import Vue from 'vue'
require('@/assets/css/main.css')

const hlCache = new Map()
Vue.directive('highlight-update', {

  bind(el, { value }) {
    hlCache.set(el, value)
  },
  componentUpdated(el, { value }) {
   if (hlCache.get(el) !== value) {
     hlCache.set(el, value)
     el.classList.remove('highlight-update')
     el.classList.add('highlight-update')
     
     setTimeout(() => {
       el.classList.remove('highlight-update')
     }, 500)
   }
  },
  unbind(el) {
    hlCache.delete(el)
  }
})