import Vue from 'vue'
import { rruleToText } from '@/components/serviceLoops'
import { capitalize } from 'lodash-es'
const { DateTime } = require("luxon")

const formatDateTime = function(dt : any, format : any = DateTime.DATETIME_MED) {
    if (!dt) return ""
    return dt.toLocaleString(format)
}
const formatDate = function(d : any, format : any = DateTime.DATETIME_MED) {
    if (!d) return ""
    return DateTime.fromISO(d).toLocaleString(format)
}
const dateRelative = function(d : string) {
    if (!d) return ""
    return DateTime.fromISO(d).toRelative()
}

Vue.filter('capitalize', capitalize)
Vue.filter('rruleToText', rruleToText)
Vue.filter('formatDate', formatDate)
Vue.filter('dateRelative', dateRelative)
Vue.filter('formatDateTime', formatDateTime)