
import {Component} from 'vue-property-decorator'
import { NavigationGuardMixin } from '@okta/okta-vue'
import LoadingScreen from '@/components/LoadingScreen.vue'
import UserProfileMenu from "@/components/user/UserProfileMenu.vue"
import UserNotifications from "@/components/user/UserNotifications.vue"
import NavigationMenu from "@/components/NavigationMenu.vue"
import {ApiResource, FormsMetadataResource, SettingsResource} from "@/resources"
import ServerStatus from '@/components/ServerStatus.vue'
import DevBar from '@/components/DevBar.vue'

@Component({components : {DevBar, ServerStatus, LoadingScreen, UserProfileMenu, UserNotifications, NavigationMenu}})
export default class AppView extends NavigationGuardMixin {
  isLoading : boolean = true
  drawer : boolean = false
  build : any = null

  get toolbarExtensionActive() {
    return this.$route?.meta?.toolbarExtensionActive
  }

  get toolbarColor() {
    const color = this.$route?.meta?.toolbarColor
    return color ? color : "primary"
  }

  get toolbarExtensionHeight() {
    if (!this.toolbarExtensionActive) {
      return 0
    }
    const height = this.$route?.meta?.toolbarExtensionHeight
    return height ? height : 48
  }

  async mounted() {
    Promise.all([
      FormsMetadataResource.Instance.get(),
      SettingsResource.DefaultsInstance.get(),
      SettingsResource.Instance.get(),
      ApiResource.Instance.maintenanceSystems.getAssociation()
    ]).then(() => {
      this.isLoading = false
    })

    this.build = (await new ApiResource("admin/build").get(false)).data
  }
  
}
