import _Vue from 'vue'


/**
 * Logger which delegates to the underling console object except for the debug
 * call which only logs if the NODE_ENV is set to "development"
 */
class Logger {
    warn(message?: any, ...optionalParams: any[]) : void {
        optionalParams.length 
            ? console.warn(message, optionalParams)
            : console.warn(message)
        
    }

    log(message?: any, ...optionalParams: any[]) : void {
        optionalParams.length 
            ? console.log(message, optionalParams)
            : console.log(message)
        
    }

    debug(message?: any, ...optionalParams: any[]) : void {
        if (process.env.NODE_ENV === 'development') {
            optionalParams.length 
                ? console.debug(message, optionalParams)
                : console.debug(message)
        }
    }

    error(message?: any, ...optionalParams: any[]): void {
        optionalParams.length 
                ? console.error(message, optionalParams)
                : console.error(message)
    }
}

/**
 * Declare new event bus variable in the prototype
 */
declare module 'vue/types/vue' {
    interface Vue {
        $log: Logger
    }
}

/**
 * Vue plugin install function
 * @param Vue 
 * @param options 
 */
export default function LoggerPlugin(Vue: typeof _Vue, options?: any): void {
    Vue.prototype.$log = new Logger()
}
