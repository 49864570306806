
import { SettingsResource } from '@/resources'
import AsyncComputed from 'vue-async-computed-decorator'
import { Component, Vue } from 'vue-property-decorator'
@Component
export default class ServerStatus extends Vue {
  show : boolean = false
  connected : boolean = true 
  initialServerUp : boolean = true
  lastConnection : number = Date.now()
  loopId !: any

  setup() {
    this.$eventBus.on('serverPing', this.updateConnection)
    this.loopId = setInterval(this.testConnection, 1000)
  }
  tearDown() {
    this.$eventBus.off('serverPing', this.updateConnection)
    clearInterval(this.loopId)
  }

  resetTimer(timeout: number) {
    clearInterval(this.loopId)
    this.loopId = setInterval(this.testConnection, timeout)
  }

  updateConnection() {
    this.lastConnection = Date.now()
  }
  
  @AsyncComputed()
  async serverUp() {
    this.initialServerUp = true
    try {
     //@ts-ignore
      var response = await fetch(process.env.VUE_APP_SERVER_UP_ENDPOINT)
      this.initialServerUp = response.ok
    }
    catch (e) {
      this.initialServerUp = false
      this.show = true
      console.error("Can not connect to server: " + process.env.VUE_APP_SERVER_UP_ENDPOINT)
    }
    return this.initialServerUp
    
  }

  testConnection() {
    var clientTimeout = 2000
    try {
      clientTimeout = parseInt(SettingsResource.defaultSetting("clientTimeout"), 10)
    } 
    // eslint-disable-next-line
    catch(e) {

    }

    var serverTimeout = clientTimeout
    var lastUpdated = Date.now() - this.lastConnection

    // add 5 extra seconds to the timeout
    var lastConnectedStatus = this.connected
    this.connected = lastUpdated < (serverTimeout + 5000)

    // show if status changed
    this.show = !this.connected || lastConnectedStatus != this.connected
  }

  beforeMount() { this.setup() }
  beforeUnmount() { this.tearDown() } // vue3
  beforeDestroy() { this.tearDown()} // vue2


}
