import 'reflect-metadata'
import Vue from 'vue'

// testing as info() function on $log not found by okta setup
import LoggerPlugin from "./plugins/loggerPlugin"
Vue.use(LoggerPlugin)

import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import "./directives"
import "./filters"
import "./plugins"

import "@/assets/css/main.css"
import "@/assets/js/main.js"

Vue.mixin({
  data: function() {
    return {
      get isDev() {
        return process.env.NODE_ENV === 'development';
      }
    }
  }
})


Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')



