import Auth from '@/auth'
import VueSSE from 'vue-sse'
import { SSEClient } from 'vue-sse/types'
import Vue from 'vue'
import _Vue from 'vue'

// https://github.com/tserkov/vue-sse/issues/21
Vue.use(VueSSE, {
    polyfill: true,
    forcePolyfill: true,
    url: process.env.VUE_APP_API_BASE_URL + '/sse/updates',
    withCredentials: true,
});

class SseClient {
    client !: SSEClient | undefined
 
    constructor() {
    }

    disconnect() {
        if (this.client) {
            this.client.disconnect()
            this.client = undefined
        }
    }

    resetAccessToken(accessToken : any) {
        this.disconnect()
        this.client = Vue.$sse.create({polyfillOptions: {
            headers: {
              "Authorization": 'Bearer ' + accessToken,
              "Ketting" : "1"
            },
        }});
        this.client.on('error', (e) => {
            //console.error('SSE lost connection or failed to parse: ', e);
            if (e?.status === 401){
                Auth.signOut()
            }
        });
        
        this.client.on('message', (data: any, lastEventId: string) => {
            if (data === "PING") {
                Vue.prototype.$log.debug("Sse ping received.")
                Vue.prototype.$eventBus.emit("serverPing", true)
            }
            else {
                Vue.prototype.$log.debug("Sse update event: " + data)
                Vue.prototype.$eventBus.emit("entityUpdate", data)
            }
        });
        

        this.client.connect().then(() => {
            Vue.prototype.$log.debug('SSE connected.')
        }).catch((err) => {
            Vue.prototype.$log.error('SSE failed to connect: ', err)
        })
    }
}

const sseClient = new SseClient();

/**
 * Auth token manager hooks to update the access token for the SSE client.
 */
Auth.authStateManager.subscribe((authState : any) => {
    if (authState.isAuthenticated) {
        Vue.prototype.$log.debug("SSE registerting JWT access token (authenticated).")
        sseClient.resetAccessToken(authState.accessToken.accessToken);
    } else {
        Vue.prototype.$log.debug("SSE deregistering access token (not authenticated).")
        sseClient.disconnect()
    }
});


/**
 * Vue plugin install function
 * @param Vue 
 * @param options 
 */
export default function SseClientPlugin(Vue: typeof _Vue, options?: any): void {
}
