import { OktaAuth } from '@okta/okta-auth-js'
import Vue from 'vue'

const LOGIN_CALLBACK_PATH = '/login/callback'
const Auth = new OktaAuth({
    issuer: 'https://' + process.env.VUE_APP_OKTA_DOMAIN + '/oauth2/default',
    clientId: process.env.VUE_APP_OKTA_CLIENT_ID,
    redirectUri: window.location.origin + LOGIN_CALLBACK_PATH,
    scopes: ['openid', 'profile', 'email',]
})
Auth.tokenManager.on('expired', async function () {
    Vue.prototype.$log.log('OKTA token has expired, signing out.');
    await Auth.signOut()
});

export default Auth
export {LOGIN_CALLBACK_PATH}