
import { Component, Vue } from "vue-property-decorator";


@Component({ components: {  } })
export default class NavigationMenu extends Vue {

    items = [
        {name: "Dashboard", route: "/", icon: "mdi-view-dashboard"},
        {name: "Property map", route: "/properties/map", icon: "mdi-map-marker"},
        //{name: "Scheduling", route: "/scheduling", icon: "mdi-calendar-clock",},
        {sep: true, roles: ['Administrators']},
        {name: "Admin dashboard", route: "/admin", icon: "mdi-view-dashboard",},
        {name: "Maintenance Systems", route: "/admin/systems", icon: "mdi-graph-outline",},
        {name: "Maintenance Equipment", route: "/admin/items", icon: "mdi-cog-outline",},
        {name: "Users", route: "/admin/users", icon: "mdi-account-group",},
        {name: "Scheduling Admin", route: "/admin/scheduling", icon: "mdi-calendar-clock",},
        //{name: "Inventory", route: "/inventory", icon: "mdi-dolly"},
        //{sep: true, roles: ['Administrators']},
        //{name: "Experimental", route: "/experimental", icon: "mdi-test-tube", roles: ['Administrators']},
        //{name: "Experimental2", route: "/experimental2", icon: "mdi-test-tube", roles: ['Administrators']},
        //{name: "Components", route: "/components", icon: "mdi-view-quilt", roles: ['Administrators']},
        //{name: "Components", route: "/components", icon: "mdi-view-quilt", roles: ['Administrators']},
        //{sep: true},
        //{name: "Administration", icon: "mdi-cogs", roles: ['Administrators'], children : [
        //  {name: "Data migration tool", route: "/admin/datamigration", icon: "mdi-database-sync"},
        //  {name: "Systems", route: "/admin/systems", icon: "mdi-graph-outline"},
        //  {name: "Workflows", route: "/admin/workflows", icon: "mdi-state-machine"},          
        //]},
    ]

    get filteredItems () {
        return this.items //.filter(i => !i.roles || this.hasRoles(i.roles))
    }
    
}
