
import { Component, Vue } from 'vue-property-decorator'
import AsyncComputed from 'vue-async-computed-decorator'
import UserAvatar from './UserAvatar.vue'
import { UserResource } from '@/resources'

@Component({components : {UserAvatar}})
export default class UserProfileMenu extends Vue {
  menu : boolean = false
  loggingOut : boolean = false
  userResource !: UserResource

  @AsyncComputed()
  async user() {
    const authUser = await this.$auth.getUser()
    this.userResource = new UserResource(authUser.email)
    await this.userResource.get()
    return authUser
  }

  @AsyncComputed()
  async groups() {
    const theUser = await this.$auth.getUser()
    const groups : any[] = theUser.groups
    return groups ? groups.filter(g => g !== "Everyone").join(", ") : ""
  }

  async logout () {
    this.loggingOut = true
    await this.$auth.signOut().finally(() => {
      this.loggingOut = false
    })
  }
}
