import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Auth, {LOGIN_CALLBACK_PATH} from '@/auth'
import OktaVue from '@okta/okta-vue'
import { LoginCallback } from '@okta/okta-vue'
import AppView from '@/views/AppView.vue'
import { PropertyMaintenanceSystemsResource, PropertyResource, ClientResource, client, MaintenanceJobResource } from '@/resources'
const ClientsView                     = () => import(/* webpackChunkName: "app-group" */ '@/views/ClientsView.vue')
const PropertiesView                  = () => import(/* webpackChunkName: "app-group" */ '@/views/PropertiesView.vue')
const PropertiesMapView               = () => import(/* webpackChunkName: "app-group" */ '@/views/PropertiesMapView.vue')
const PropertyMaintenanceSystemView   = () => import(/* webpackChunkName: "app-group" */ '@/views/PropertyMaintenanceSystemView.vue')
const ClientDetail                    = () => import(/* webpackChunkName: "app-group" */  '@/components/clients/ClientDetail.vue')
const PropertyDetail                  = () => import(/* webpackChunkName: "app-group" */  '@/components/properties/PropertyDetail.vue')
const PropertyView                    = () => import(/* webpackChunkName: "app-group" */  '@/components/properties/PropertyView.vue')
const PropertyOverview                = () => import(/* webpackChunkName: "app-group" */  '@/components/properties/PropertyOverview.vue')
const MaintenanceSystemsView          = () => import(/* webpackChunkName: "app-group" */ '@/views/MaintenanceSystemsView.vue')
const UsersView                       = () => import(/* webpackChunkName: "app-group" */ '@/views/UsersView.vue')
const SchedulingView                  = () => import(/* webpackChunkName: "app-group" */ '@/views/SchedulingView.vue')
const PropertyMaintenanceSystemDetail = () => import(/* webpackChunkName: "app-group" */ '@/components/properties/PropertyMaintenanceSystemDetail.vue')
const PropertyMaintenanceJobDetail    = () => import(/* webpackChunkName: "app-group" */ '@/components/properties/PropertyMaintenanceJobDetail.vue')
const AdminSchedulingView             = () => import(/* webpackChunkName: "app-group" */ '@/views/AdminSchedulingView.vue')
const MaintenanceItemsView            = () => import(/* webpackChunkName: "app-group" */ '@/views/MaintenanceItemsView.vue')
const AdminDashboardView              = () => import(/* webpackChunkName: "app-group" */ '@/views/AdminDashboardView.vue')
const TestView                        = () => import(/* webpackChunkName: "app-group" */ '@/views/TestView.vue') 
const Dashboard                       = () => import(/* webpackChunkName: "app-group" */ '@/components/Dashboard.vue') 

Vue.use(VueRouter)
Vue.use(OktaVue, { oktaAuth: Auth })

const routes: Array<RouteConfig> = [
  {path: LOGIN_CALLBACK_PATH, component: LoginCallback },
  {path: '/', component: AppView, meta: {requiresAuth: true}, children: [
    {path: '', component: SchedulingView, meta: {requiresAuth: true} },
    {path: 'clients', component: ClientsView, meta : {}, children: [
      {path: ':cid', name: "client", component: ClientDetail, props: route => ({ resource : new ClientResource(route.params.cid)})}
    ]},
    {path: 'properties', component: PropertiesView, children: [
        {path: 'map', name: "properties-map", component: PropertiesMapView},
        {path: ':pid', component: PropertyView, props: route => ({ resource : new PropertyResource(route.params.pid + "?projection=propertySummary")}), children: [
          {path: '', name: "property", component: PropertyOverview},
          {path: 'system/:pmsId', component: PropertyMaintenanceSystemView, props: route => ({ resource : new PropertyMaintenanceSystemsResource(route.params.pmsId + "?projection=propertyMaintenanceSystemSummary")}), children: [
            {path: 'job/:jid', name: 'job', component: PropertyMaintenanceJobDetail, meta : {}, props: route => ({resource : new MaintenanceJobResource(route.params.jid + "?projection=maintenanceJobSummary")})},
            {path: '', name: 'propertySystem', component: PropertyMaintenanceSystemDetail, meta : {}},
          ]},
        ]},
      ]},
    {path: 'scheduling/:eventDate?', name: 'scheduling', component: SchedulingView, meta : {}, props: route => ({eventDate : route.params.eventDate})},
    {path: 'admin', name: "admin", component: AdminDashboardView, meta : {toolbarColor: "black"}},
    {path: 'admin/systems', name: "systems", component: MaintenanceSystemsView, meta : {toolbarColor: "black"}},
    {path: 'admin/items', name: "items", component: MaintenanceItemsView, meta : {toolbarColor: "black"}},
    {path: 'admin/scheduling', name: "adminScheduling", component: AdminSchedulingView, meta : {toolbarColor: "black"}},
    {path: 'admin/users', name: "users", component: UsersView, meta : {toolbarColor: "black"}},
    {path: 'test', name: "test", component: TestView, meta : {toolbarColor: "black"}},
  ]}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {

  // if we don't have a toolbar color on this route, check for other matching routes, set color
  // if found.
  if (!to.meta?.toolbarColor) {
    to.matched.forEach(route => {
      if (route.meta.toolbarColor) {
        if (!to.meta) {
          to.meta = {} 
        }
        to.meta.toolbarColor = route.meta.toolbarColor
      } 
    })
  }

  next()
  
})



export default router
