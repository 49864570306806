import Vue from 'vue'
import AsyncUnwrapPlugin from "./asyncUnwrap"
import AsyncComputedPlugin from 'vue-async-computed'
import PortalVue from 'portal-vue'
import VCalendar from 'v-calendar'
import EventBusPlugin from "./eventBus"
import SseClientPlugin from "./sseClient"
import VueMeta from 'vue-meta'
import LoggerPlugin from './loggerPlugin'

Vue.use(LoggerPlugin)
Vue.use(AsyncComputedPlugin)
Vue.use(AsyncUnwrapPlugin)
Vue.use(PortalVue)
Vue.use(VCalendar, {componentPrefix: 'vc'})  // So we don't clash with vuetify - Use <vc-calendar /> instead of <v-calendar /> 
Vue.use(EventBusPlugin)
Vue.use(SseClientPlugin)
Vue.use(VueMeta)
