import { capitalize } from '@/util'
import { RRule } from 'rrule'

/**
 * Converts an RRULE to a human readable description.
 * @param r 
 * @returns 
 */
const rruleToText = function(r:string) {
    if (typeof r !== 'string') return ''
    try {
        const roptions = RRule.parseString(r);
        // https://github.com/jakubroztocil/rrule/issues/389
        if (r.indexOf("FREQ=WEEKLY") > 0 && r.indexOf("BYDAY") == -1) {
            roptions.byweekday = []
        }
        const rrule = new RRule(roptions, false)
        const description = rrule.toText()

        return capitalize(description)
    } catch (e) {
        return ""
    }
}

export {
    rruleToText
}