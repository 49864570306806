import {AppointmentResource, AssociationResource, ClientResource, ContactResource, EntityResource, HealthReportResource, IHateoasResource, JobTemplateResource, MaintenanceItemPrototypeResource, MaintenanceItemPackageResource, MaintenanceItemTypeResource, MaintenanceJobResource, MaintenanceLogResource, MaintenanceSystemsResource, MediaResource, PropertyMaintenanceItemResource, PropertyMaintenanceSystemsResource, PropertyResource, ServiceLoopResource, ServiceLoopTemplateResource, SettingsResource, UserResource, UserSettingsResource, SchemaPresetModelResource, SchemaPresetKeyValueResource } from "@/resources"
import { LinkVariables } from "ketting/dist/link"
import { DateTime, DurationLike, DurationLikeObject } from "luxon"
import { PropType } from "vue"
import AsyncComputed from "vue-async-computed-decorator"
import { Component, Vue, Prop, Mixins, Watch } from "vue-property-decorator"

/**
 * Decorator function to flag components not given resources that are projections of a specific name.
 * Use to help prevent components using the wrong shaped data.
 * @param name 
 * @returns 
 */
export function RequiredProjections(...names: string[]) {
  return (ctor: Function) => {
    ctor.prototype.requiredProjections = names
  }
}

@Component
class BaseResourceComponent<T extends EntityResource> extends Vue {

  @Prop() resourceData!: any
  @Prop({ type: EntityResource}) readonly resource!: T
  @Prop({ type: AssociationResource}) readonly associationResource!: AssociationResource<T | T[]>
  @Prop({ type: Object as PropType<LinkVariables>, default: () => {}}) linkVariables !: LinkVariables
  @Prop({}) readonly resourceUpdateCallback !: Function
  @Prop({}) readonly filterFunction !: Function
  @Prop({default: -1}) readonly limit !: number
  @Prop() readonly projection !: string
  
  /**
   * Additional URI patterns to watch for updates on, will trigger an update of this component.
   */
  @Prop() uriPatternsToWatch !: string[]

  notFound : boolean = false
  isDirty : boolean = false
  refreshCache : boolean = false
  resources : T[] = []
  requiredProjections !: string[]
  isLoaded : boolean = false
  additionalWatchedURIs : string[] = []
  loadedOnce : boolean = false
  defaultLinkVariables : LinkVariables = {}

  fetchError : boolean = false
  fetchException: Error | null = null
  

  customSortField : (er : T) => any = (er : T) => DateTime.fromISO(er.data().createdDate as string)
  customSortDirection !: "asc" | "desc"
  customSortCaseInsensitive !: boolean
  
  /**
   * Size of data array, 1 if not an array, 0 if non existent.
   */
  size : any = 0


  /**
   * For use in renderless situations
   * @returns 
   */
  render() {
    if (this.$scopedSlots.default) {
      return this.$scopedSlots.default({
        updating: this.updating, 
        rdata : this.rdata,
        size : this.size,
        success : this.success, 
        ready : this.ready,
        loaded : this.loaded,
        error : this.error,
        exception : this.exception,
        notFound : this.notFound, 
        resource : this.resource,
        resources : this.resources,
        associationResource : this.associationResource})
    }
    return this.$slots.default
  }

  /**
   * True if the resource has been fetched at least once successfully
   */
  get loaded() {
    return this.isLoaded
  }

  get ready() {
    return this.success || this.loadedOnce
  }

  get success() {
    return this.$asyncComputed.fetchResource.success
  }
  get updating() {
    return this.$asyncComputed.fetchResource.updating
  }
  get error() {
    return this.fetchError || this.$asyncComputed.fetchResource.error
  }
  get exception() {
    return this.fetchException || this.$asyncComputed.fetchResource.exception
  }

  get sortField() : any {
    if (this.customSortField) return this.customSortField
    return this.resource ? this.resource.sortField
                         : this.size ? this.resources[0].sortField : ""
  }

  get sortCaseInsensitive() : boolean {
    if (this.customSortCaseInsensitive) return this.customSortCaseInsensitive
    return false
  }

  get sortDirection() : "asc" | "desc" {
    if (this.customSortDirection) return this.customSortDirection
    return this.resource ? this.resource.sortDirection
                         : this.size ? this.resources[0].sortDirection : "desc"
  }

  defaultSort(ers : EntityResource[]) {
    return ers.length 
        ? this.associationResource!.orderedBy(this.sortField, this.sortDirection, this.sortCaseInsensitive) 
        : ers
  }


  defaultFilter(ers : any) {
    return this.filterFunction ? ers.filter((er:any) => this.filterFunction(er)) : ers
  }

  @Watch("$asyncComputed.fetchResource.success")
  fetchSuccess(newVal : any) {
    if (newVal && this.resourceUpdateCallback) this.$nextTick(this.resourceUpdateCallback())
  }

  @Watch("linkVariables")
  @Watch("defaultLinkVariables")
  _linkVariablesUpdated(newVal : any) {
    if (newVal) {
      this.update()
    }
  }

  @Watch("resource", {immediate: true})
  _resourceUpdated(newVal : any) {
    if (newVal) {
      this.loadedOnce = false
      this.markIsDirty()
    }
  }

  @Watch("associationResource", {immediate: true})
  _associationResourceUpdated(newVal : any) {
    if (newVal) {
      this.loadedOnce = false
      this.markIsDirty()
    }
  }

  markIsDirty() {
    this.isDirty = true
    this.fetchError = false
    this.fetchException = null
  }

  update() {
    // mark dirty + refresh cache
    this.refreshCache = true
    this.markIsDirty()
  }

  projectionCheck(hr : IHateoasResource) {

    // any projection ?
    if (this.requiredProjections && this.requiredProjections.includes("*")) return

    // check that required projection matches the projection used
    if (this.requiredProjections && hr.projectionName && this.requiredProjections.includes(hr.projectionName) == false) {
      throw new Error("This component requires a projections of type '" + this.requiredProjections + "' but data is of the shape '" + hr.projectionName + "'. (" + this.componentTag + ")")
    }

    // check that required projection matches the projection used, or if we are doing an association, if the projection
    // was provided via the prop
    if (this.requiredProjections 
    && (hr.isProjection == false 
        && (this.associationResource && this.requiredProjections.includes(this.projection) == false))) {
      throw new Error("This component requires a projection of type '" + this.requiredProjections + "' but data has no shape (full resource - " + this.componentTag + ").")
    }

    // if no projection required, error if projection used, we want them to declare explicitly
    if (hr.isProjection && (!this.requiredProjections && this.projection  && this.projection != hr.projectionName)) {
      throw new Error("This component contains data of the shape '" + hr.projectionName + "', please specify this using the @RequiredProjections decorator. (" + this.componentTag + ")")
    }
  }

  /**
   * Callback for components after fetchResource() has been successfully called.
   */
  postEntityUpdate() {

  }

  /**
   * Callback for components after fetchResource() has been successfully called.
   */
    postInitialLoad() {

    }

  /**
   * Computed getter for data, ensures we are always using the data from the resource itself.
   */
  get rdata() {
    // reference to force update
    this.isDirty
    
    if (this.resourceData) {
      return this.resourceData
    }
    else if (this.resource && this.isLoaded) {
      return this.notFound ? null : this.resource.data()
    }
    if (this.associationResource && this.isLoaded) {
      return this.notFound 
        ? null 
        : this.associationResource.isArray 
            ? this.resources.map(item => item.data()) 
            : this.size > 0 ? this.resources[0].data() : null
    }
    return null
  }

  @AsyncComputed()
  async fetchResource() {

    // if given the data, check for an array, set size
    if (this.resourceData) {
      this.size = Array.isArray(this.resourceData) ? this.resourceData.length : 1
      this.isLoaded = true
      this.notFound = false

      // nextTick avoids any reactivity side effects from values within postEntityUpdate 
      this.$nextTick(this.postEntityUpdate)

      return
    }

    // we may have no resources if this is being used in a form context when
    // adding new vs editing where we would have a resource
    if (!this.resource && !this.associationResource) {

      // data may have been set before, so wipe it
      this.resources.splice(0, this.resources.length)
      this.size = 0

      this.isLoaded = false
      this.notFound = false

      // nextTick avoids any reactivity side effects from values within postEntityUpdate 
      this.$nextTick(this.postEntityUpdate)

      return
    }

    // if not dirty and we already have data, return
    if (!this.isDirty && this.isLoaded) {
      
      // nextTick avoids any reactivity side effects from values within postEntityUpdate 
      this.$nextTick(this.postEntityUpdate)
      
      return
    }

    // if previous error, prevent looping
    if (this.fetchError) {
      return
    }

    if (this.associationResource) {

      // projection check
      this.projectionCheck(this.associationResource as IHateoasResource)
      
      const requestLinkVariables  = {...this.defaultLinkVariables, ...this.linkVariables}
      if (this.projection) {
        requestLinkVariables.projection = this.projection
      }

      this.$emit("resourceUpdating", true)
      return this.associationResource.getAssociation(requestLinkVariables, !this.refreshCache).then((er : EntityResource | EntityResource[]) =>
      {
        // association could represent a single or array of entities
        if (this.associationResource.isArray) {
          const sorted = this.defaultSort(er as []) 
          const sortedAndFiltered = this.defaultFilter(sorted)

          if (sortedAndFiltered.length > 0 && this.limit != -1) {
            sortedAndFiltered.splice(0, sortedAndFiltered.length - this.limit)
          }
          
          this.resources.splice(0, this.resources.length, ...sortedAndFiltered)
          this.size = this.resources.length
        }
        else {
          this.resources.splice(0, this.resources.length, ...[er as T])
          this.size = 1
        }
        this.isLoaded = true
        this.notFound = false

        if (this.loadedOnce) {
          this.$emit("resourceUpdated", this.associationResource)
        } else {
          this.loadedOnce = true
          this.$emit("resourceLoaded", this.associationResource)
          this.$emit("resourceUpdated", this.associationResource)

          // nextTick avoids any reactivity side effects from values within postEntityUpdate 
          this.$nextTick(this.postInitialLoad)
        }

        // nextTick avoids any reactivity side effects from values within postEntityUpdate 
        this.$nextTick(this.postEntityUpdate)

      }).catch((e : any) => {
        if (e?.status === 404) {
          this.resources = this.resources.splice(0, this.resources.length)
          this.isLoaded = true
          this.notFound = true
          this.size = 0
        } else {
          this.fetchError = true
          this.fetchException = e
          console.error(e)
          throw e
        }
      }).finally(() => {
        this.isDirty = false
        this.refreshCache = false
        this.$emit("resourceUpdating", false)
      })
    } 

    if (!this.resource) {
      throw new Error("At least one resource or associationResource property must be set on component: " + this.componentTag)
    } 

    // projection check
    this.projectionCheck(this.resource as IHateoasResource)

    this.$emit("resourceUpdating", true)
    return (this.resource as EntityResource).get(!this.refreshCache).then((s : any) => {
        this.size = 1
        this.notFound = false
        this.isLoaded = true

        if (this.loadedOnce) {
          this.$emit("resourceUpdated", this.resource)
        } else {
          this.loadedOnce = true
          this.$emit("resourceLoaded", this.resource)
          this.$emit("resourceUpdated", this.resource)

          // nextTick avoids any reactivity side effects from values within postEntityUpdate 
          this.$nextTick(this.postInitialLoad)
        }

        // nextTick avoids any reactivity side effects from values within postEntityUpdate 
        this.$nextTick(this.postEntityUpdate)

      }).catch((e : any) => {
        if (e?.status === 404) {
          this.isLoaded = true
          this.notFound = true
          this.size = 0
        } else {
          this.fetchError = true
          this.fetchException = e
          console.error(e)
          throw e
        }
      }).finally(() => {
        this.isDirty = false
        this.refreshCache = false
        this.$emit("resourceUpdating", false)
      })
  }

  get componentTag() {
    return this.$vnode.componentOptions!.tag ? this.$vnode.componentOptions!.tag : this.$vnode.tag
  }

  clearAdditionalWatchedURIs() {
    this.additionalWatchedURIs.splice(0)
  }
  addAdditionalWatchedURI(uri : string) {
    if (this.additionalWatchedURIs.indexOf(uri) === -1) {
      this.additionalWatchedURIs.push(uri)
    }
  }
  addAdditionalWatchedURIs(uris : string[]) {
    for (const u of uris) {
      this.addAdditionalWatchedURI(u)
    }
  }

  matchesWatchedURIs(uri : string) {
    // try additional ones first (added manually)
    for (let i=0; i < this.additionalWatchedURIs.length; i++) {
      if (uri.match(this.additionalWatchedURIs[i])) {
        return true
      }
    }

    // try ones passed via prop
    if (!this.uriPatternsToWatch || this.uriPatternsToWatch.length == 0) {
      return false
    }

    for (let i=0; i < this.uriPatternsToWatch.length; i++) {
      if (uri.match(this.uriPatternsToWatch[i])) {
        return true
      }
    }
    return false
   }

  entityUpdated(event : string) {
    const [type, uri] = event.split(": ")

    if (this.resource) {
      const resourceUriWithoutQueryParams = this.resource.resource.uri.split("?")[0]

      if (this.resource.resource.uri.indexOf("projection") > -1
       && resourceUriWithoutQueryParams.endsWith(uri)) {
        // TODO AQBO-62 - we need to update all shapes
        this.resource.fullResource().resource.clearCache()
      }
      
      if (resourceUriWithoutQueryParams.endsWith(uri) || this.matchesWatchedURIs(uri)) {
        Vue.prototype.$log.debug("UPDATING ENTITY (" + type + ") : " + this.componentTag + " -> " + this.resource.resource.uri)
        
        // update unless deleting
        if (type !== "DELETE") {
          this.update()
        }
      }
    }
    // note, resource may not have been fetched yet, so check
    else if (this?.associationResource?.associationResource) {
      
      // check for updates for this association
      const resourceUriWithoutQueryParams = this.associationResource.associationResource.uri.split("?")[0]
      if (resourceUriWithoutQueryParams.endsWith(uri) || this.matchesWatchedURIs(uri)) {
        Vue.prototype.$log.debug("UPDATING ASSOCIATION (" + type + ") : " + this.componentTag + " -> " + this.associationResource.associationResource.uri)
        this.update()
      }

      // check for updates to specific resources within the association, force update
      for (const [i,r] of this.resources.entries()) {
        const rUri = r.resource.uri.split("?")[0]
        if (rUri.endsWith(uri)) { // too generic -> } || this.matchesWatchedURIs(uri)) {
          // if a del, do nothing, allow association to refresh list.
          if (type != "DELETE") {
            // else reload this particular entity
            r.get(false).then(() => {
              Vue.prototype.$log.debug("UPDATING ENTITY (" + type + ") : " + this.componentTag + " -> " + r.resource.uri)
              this.$set(this.resources, i, r)
            })
          } 
          // else we have an item in our collection that has been removed, force update
          else {
            Vue.prototype.$log.debug("UPDATING ASSOCIATION (" + type + ") : " + this.componentTag + " -> " + this.associationResource.associationResource.uri)
            this.update()
          }
        }
      }
    }
  }

  get collectionResource() : T {
    throw new Error("Class must implement collectionResource().")
  }

  beforeMount() {
    this.$eventBus.on('entityUpdate', this.entityUpdated)
  }
  beforeUnmount() { // vue3
    this.$eventBus.off('entityUpdate', this.entityUpdated)
  }
  beforeDestroy() {  // vue2
    this.$eventBus.off('entityUpdate', this.entityUpdated)
  }
}

@Component
export class BaseEntityResourceComponent extends Mixins<BaseResourceComponent<EntityResource>>(BaseResourceComponent) {  
}

@Component
export class BaseUserResourceComponent extends Mixins<BaseResourceComponent<UserResource>>(BaseResourceComponent) {  
}

@Component
export class BaseSchemaPresetModelResourceComponent extends Mixins<BaseResourceComponent<SchemaPresetModelResource>>(BaseResourceComponent) {  
}

@Component
export class BaseSchemaPresetKeyValueResourceComponent extends Mixins<BaseResourceComponent<SchemaPresetKeyValueResource>>(BaseResourceComponent) {  
}

@Component
export class BaseUserSettingsResourceComponent extends Mixins<BaseResourceComponent<UserSettingsResource>>(BaseResourceComponent) {  
  get collectionResource() {
    return new UserSettingsResource()
  }
}

@Component({})
export class BaseClientComponent extends Mixins<BaseResourceComponent<ClientResource>>(BaseResourceComponent) {

  get clientUtil() {
    return ClientResource
  }

  get contactUtil() : any {
    return ContactResource
  }

  get collectionResource() {
    return new ClientResource()
  }

  get clientName() {
    return this.clientUtil.getClientName(this.rdata)
  }

  get mediaAssociationResource() {
    return this.resource.media
  }

  get propertiesAssociationResource() {
    return this.resource.properties
  }

  get jobsAssociationResource() {
    return this.resource.jobs
  }

  get contactsResource() {
    return this.resource.contacts
  }
}

@Component({})
export class BaseContactComponent extends Mixins<BaseResourceComponent<ContactResource>>(BaseResourceComponent) {

  get contactUtil() : any {
    return ContactResource
  }

  get collectionResource() {
    return new ContactResource()
  }
}

@Component({})
export class BasePropertyComponent extends Mixins<BaseResourceComponent<PropertyResource>>(BaseResourceComponent) {

  get propertyUtil() {
    return PropertyResource
  }

  get clientUtil() {
    return ClientResource
  }

  get collectionResource() {
    return new PropertyResource()
  }

  get propertyAddress() {
    return this.propertyUtil.getPropertyAddress(this.rdata)
  }

  get propertyAddressNoCity() {
    return this.propertyUtil.getPropertyAddressNoCity(this.rdata)
  }

  get healthSummaryAssociationResource() {
    return this.resource.healthSummary
  }

  get mediaAssociationResource() {
    return this.resource.media
  }

  get clientAssociationResource() {
    return this.resource.client
  }

  get jobsAssociationResource() {
    return this.resource.jobs
  }
}


@Component({})
export class BaseHealthReportComponent extends Mixins<BaseResourceComponent<HealthReportResource>>(BaseResourceComponent) {  
  
  customSortField = (er : HealthReportResource) => DateTime.fromISO(er.data().reportDate)
  
  get healthUtil() {
    return HealthReportResource
  }

  get mediaAssociationResource() {
    return this.resource.media
  }

  get collectionResource() {
    return new HealthReportResource()
  }
}

@Component
export class BaseMediaComponent extends Mixins<BaseResourceComponent<MediaResource>>(BaseResourceComponent) { 
  
  get thumbnailUrl() {
    return this.resource ? this.resource.thumbnailUrl() : new MediaResource(this.rdata.id).thumbnailUrl()
  }
  get viewUrl() {
    return this.resource ? this.resource.viewUrl() : new MediaResource(this.rdata.id).viewUrl()
  }
  get downloadUrl() {
    return this.resource ? this.resource.downloadUrl() : new MediaResource(this.rdata.id).downloadUrl()
  }
  get isPDF() {
    return this.rdata.mimeType === "application/pdf"
  }
  
}

@Component({name: "BaseMaintenanceJobComponent"})
export class BaseMaintenanceJobComponent extends Mixins<BaseResourceComponent<MaintenanceJobResource>>(BaseResourceComponent) {  
  
  customSortField = (er : MaintenanceJobResource) => DateTime.fromISO(er.data().startDate)
  customSortDirection : "asc" | "desc" = "asc"

  bumpingProgress : any = {}

  get collectionResource() {
    return new MaintenanceJobResource()
  }

  static COMPLETED_CANCELLED = [MaintenanceJobResource.STATUS_CLOSED_COMPLETED, MaintenanceJobResource.STATUS_CLOSED_CANCELLED]

  /**
   * Only show bump if not completed/cancelled.
   */
  get bumpAvailable() {
    return this.rdata && !BaseMaintenanceJobComponent.COMPLETED_CANCELLED.includes(this.rdata.status)
  }

  bumpAvailableMultiple(multipleJobs : MaintenanceJobResource[]) {
    const oneNotGood = multipleJobs.find(j => j.data() && BaseMaintenanceJobComponent.COMPLETED_CANCELLED.includes(j.data().status))
    return !oneNotGood
  }

  bumpJob(bumpValue : DurationLikeObject | string, i : any) {
    // TODO errors
    this.$set(this.bumpingProgress, i, true)

    const newStartDate = typeof bumpValue === 'string' 
        ? DateTime.fromISO(bumpValue).setZone('utc').toISO() // ensure gmt
        : DateTime.fromISO(this.bumpDate(this.rdata.startDate, bumpValue)).setZone('utc').toISO()

    // TODO errors
    this.bumpJobPromise(this.resource, bumpValue, i).finally(() => this.$set(this.bumpingProgress, i, false))
  }

  bumpJobPromise(aResource : MaintenanceJobResource, bumpValue : DurationLikeObject | string, i : any) {
    const newStartDate = typeof bumpValue === 'string' 
        ? DateTime.fromISO(bumpValue).setZone('utc').toISO() // ensure gmt
        : DateTime.fromISO(this.bumpDate(aResource.data().startDate, bumpValue)).setZone('utc').toISO()

    // TODO errors
    return aResource.mergePatch({
      startDate : newStartDate  // GMT above
    })
  }

  defaultBumpDates : any[] = [
    {text: "1 week", value: {weeks:1}},
    {text: "2 weeks", value: {weeks:2}},
    {text: "3 weeks", value: {weeks:3}},
    {text: "1 month", value: {months:1}}
  ]
  
  @AsyncComputed({lazy : true})
  async scheduleBump() {
    if (this.rdata && !!this.rdata.recurrence && !!this.rdata.startDate) {
      // ensure GMT
      const ndState = await MaintenanceJobResource.nextdate(this.rdata.recurrence, DateTime.fromISO(this.rdata.startDate).setZone('utc').toFormat("yyyy-MM-dd"), 1)
      return ndState.data && ndState.data.length ? {
        text: DateTime.fromISO(ndState.data[0]).toLocaleString(DateTime.DATE_HUGE),
        value: ndState.data[0]
      } : undefined
    }
    return undefined
  }

  bumpDate(dateValue : string, bumpValue : DurationLike) {
    return DateTime.fromISO(dateValue).plus(bumpValue).toISO()
  }
}
@Component({})
export class BaseMaintenanceJobLogComponent extends Mixins<BaseResourceComponent<MaintenanceLogResource>>(BaseResourceComponent) {  
  get collectionResource() {
    return new MaintenanceLogResource()
  }
}
@Component({})
export class BaseJobTemplateComponent extends Mixins<BaseResourceComponent<JobTemplateResource>>(BaseResourceComponent) {  
  get collectionResource() {
    return new JobTemplateResource()
  }
}
@Component({})
export class BaseMaintenanceItemPackageComponent extends Mixins<BaseResourceComponent<MaintenanceItemPackageResource>>(BaseResourceComponent) {  
  get collectionResource() {
    return new MaintenanceItemPackageResource()
  }
}
@Component({name: 'base-maintenance-item-prototype-component'})
export class BaseMaintenanceItemPrototypeComponent extends Mixins<BaseResourceComponent<MaintenanceItemPrototypeResource>>(BaseResourceComponent) {  
  get collectionResource() {
    return new MaintenanceItemPrototypeResource()
  }
}
@Component({})
export class BaseMaintenanceItemTypeComponent extends Mixins<BaseResourceComponent<MaintenanceItemTypeResource>>(BaseResourceComponent) {  
  get collectionResource() {
    return new MaintenanceItemTypeResource()
  }
}
@Component({})
export class BaseAppointmentComponent extends Mixins<BaseResourceComponent<AppointmentResource>>(BaseResourceComponent) {  
  
  bumpingProgress : any = {}

  static COMPLETED_CANCELLED = ['COMPLETED', 'CANCELLED']

  /**
   * Only show bump if not completed/cancelled.
   */
  get canEdit() {
    return this.rdata && !BaseAppointmentComponent.COMPLETED_CANCELLED.includes(this.rdata.status)
  }

  bumpAppointment(bumpValue : DurationLikeObject, i : any) {
    // TODO errors
    // ensure GMT
    this.$set(this.bumpingProgress, i, true)
    this.resource.mergePatch({
      startDate : DateTime.fromISO(this.bumpDate(this.rdata.startDate, bumpValue)).setZone('utc').toISO(),
      endDate : DateTime.fromISO(this.bumpDate(this.rdata.endDate, bumpValue)).setZone('utc').toISO(),
    }).finally(() => this.$set(this.bumpingProgress, i, false))
  }


  appointmentBumps : any[] = [
    {text: "1 week", value: {weeks:1}},
    {text: "2 weeks", value: {weeks:2}},
    {text: "3 weeks", value: {weeks:3}},
    {text: "1 month", value: {months:1}},
    {text: "2 months", value: {months:2}},
    {text: "3 months", value: {months:3}},
  ]

  get collectionResource() {
    return new AppointmentResource()
  }

  bumpDate(dateValue : string, bumpValue : DurationLike) {
    return DateTime.fromISO(dateValue).plus(bumpValue).toISO()
  }

  get appointmentDate() {
    // if year is not ours, show
    const startDate = DateTime.fromISO(this.rdata.startDate)
    const now = DateTime.now();

    const format = startDate.year === now.year ? 'EEEE, MMMM d' : 'EEEE, MMMM d, yyyy'
    return this.rdata ? DateTime.fromISO(this.rdata.startDate).toFormat(format) : ""
  }

  get appointmentTime() {
    return this.rdata ? DateTime.fromISO(this.rdata.startDate).toFormat('t') + " - " + DateTime.fromISO(this.rdata.endDate).toFormat('t') : ""
  }

  get propertyAddress() {
    return this?.rdata?.jobs[0]?.property ? PropertyResource.getPropertyAddressNoCity(this.rdata.jobs[0].property) : ""
  }
  get clientName() {
    return this?.rdata?.jobs[0]?.property?.client ? ClientResource.getClientName(this.rdata.jobs[0].property.client) : ""
  }

  get backgroundColor() {
    const c = this.rdata ? AppointmentResource.getStatusColor(this.rdata.status) : undefined
    return c ? c.background : undefined
  }

  get foregroundColor() {
    const c = this.rdata ? AppointmentResource.getStatusColor(this.rdata.status) : undefined
    return c ? c.foreground : undefined
  }

  get defaultBackgroundColor() {
    return AppointmentResource.getDefaultStatusBackgroundColor(this.rdata.status)
  }

  get defaultForegroundColor() {
    return AppointmentResource.getDefaultStatusForegroundColor(this.rdata.status)
  }

}
@Component({})
export class BaseSettingComponent extends Mixins<BaseResourceComponent<SettingsResource>>(BaseResourceComponent) {  
  get collectionResource() {
    return new SettingsResource()
  }
}
@Component({})
export class BasePropertyMaintenanceItemComponent extends Mixins<BaseResourceComponent<PropertyMaintenanceItemResource>>(BaseResourceComponent) {  
  get collectionResource() {
    return new PropertyMaintenanceItemResource()
  }
}
@Component({})
export class BaseMaintenanceLogComponent extends Mixins<BaseResourceComponent<MaintenanceLogResource>>(BaseResourceComponent) {  
  get collectionResource() {
    return new MaintenanceLogResource()
  }
}
@Component({})
export class BasePropertyMaintenanceSystemsComponent extends Mixins<BaseResourceComponent<PropertyMaintenanceSystemsResource>>(BaseResourceComponent) {  
  get collectionResource() {
    return new PropertyMaintenanceSystemsResource()
  }
}
@Component({})
export class BaseServiceLoopTemplateComponent extends Mixins<BaseResourceComponent<ServiceLoopTemplateResource>>(BaseResourceComponent) {  
  get collectionResource() {
    return new ServiceLoopTemplateResource()
  }
}

@Component({})
export class BaseServiceLoopComponent extends Mixins<BaseResourceComponent<ServiceLoopResource>>(BaseResourceComponent) {  
  get collectionResource() {
    return new ServiceLoopResource()
  }

  // service loops use the name of the job template they are based on
  customSortField = (er : ServiceLoopResource) => er.data().name
}

@Component({})
export class BaseMaintenanceSystemComponent extends Mixins<BaseResourceComponent<MaintenanceSystemsResource>>(BaseResourceComponent) {  
  get collectionResource() {
    return new MaintenanceSystemsResource()
  }
  
}


