import _Vue from "vue"; 

/**
 * Type declaration for the new Vue attribute
 */
declare module 'vue/types/vue' {
  interface Vue {
    $asyncUnwrap<T>(promise: () => Promise<T>): T
  }
}

/**
 * Unwraps the type of the promise used in @AsyncComputed decorator & computed property annotations.
 * @param Vue 
 * @param options 
 */
export default function AsyncUnwrapPlugin(Vue: typeof _Vue, options?: any): void {
    Vue.prototype.$asyncUnwrap = function<T>(promise: () => Promise<T>): T {
        return (promise as unknown) as T
    };
}