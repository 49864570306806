import Vue from 'vue'
import _Vue from 'vue'

// Event types
export declare type EventType = "entityUpdate" | "serverPing"

// create the bus
const vueEventBus = new Vue()

// declare our eventbus class
class EventBus {
    on(type : EventType | EventType[], callback: Function) {
        vueEventBus.$on(type, callback)
    }

    once(type : EventType | EventType[], callback: Function) {
        vueEventBus.$once(type, callback)
    }

    off(type ?: EventType, callback?: Function) {
        vueEventBus.$off(type, callback)
    }

    emit(event : EventType, ...args: any[]) {
        vueEventBus.$emit(event, ...args)
    }
}

const eventBus = new EventBus();
export {eventBus}

/**
 * Declare new event bus variable in the prototype
 */
 declare module 'vue/types/vue' {
    interface Vue {
        $eventBus: EventBus
    }
}

/**
 * Vue plugin install function
 * @param Vue 
 * @param options 
 */
export default function EventBusPlugin(Vue: typeof _Vue, options?: any): void {
    Vue.prototype.$eventBus = eventBus
}
