import { DateTime } from 'luxon'
import { capitalize } from 'lodash-es'
import Auth from '@/auth'

const formatISODate = function(dateString : string, format = DateTime.DATE_MED) {
    return DateTime.fromISO(dateString).toLocaleString(format)
}

const downloadFile = function(url : string) {
    fetch(url, {
        headers : {
            "Authorization" : "Bearer " + Auth.getAccessToken()}
        }).then((response) => {
            response.blob().then((blob) => {
                const fileURL = URL.createObjectURL(blob)
                const anchor = document.createElement("a");
                anchor.href = fileURL;

                const header = response.headers.get('Content-Disposition')
                const parts = header!.split(';');
                anchor.download = parts[1].split('=')[1].replaceAll('"', '')

                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);

                URL.revokeObjectURL(fileURL)
            })
    })
}

export {
    formatISODate,
    capitalize,
    downloadFile
}