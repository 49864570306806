import {Resource, Client, State, Action, Field, ShortCache} from 'ketting'
import {LinkVariables} from 'ketting/dist/link'
import Auth from '@/auth'
import { PatchRequestOptions } from 'ketting/dist/types'
import {orderBy, property, sortBy, takeWhile} from "lodash-es"
import Vue, { shallowReactive } from 'vue'
import _ from 'lodash'
import { use } from 'vue/types/umd'

/**
 * Our HATEOAS aware client.
 */
// https://stackoverflow.com/questions/46946380/fetch-api-request-timeout
// const controller = new AbortController()
// const timeoutId = setTimeout(() => controller.abort(), process.env.VUE_APP_API_TIMEOUT)
const client = new Client(<string>process.env.VUE_APP_API_BASE_URL)
client.use( (request, next) => {
  const authenticatedRequest = new Request(request) 

  // TODO we are getting a Spring NPE on HTTP methods that don't return a body and
  // accept hal-forms, we only want it for the meta/forms
  if (authenticatedRequest.url.indexOf("meta/forms") === -1) {
    authenticatedRequest.headers.set("Accept", "application/hal+json;q=0.9, application/json;q=0.7")
  }

  authenticatedRequest.headers.append("Authorization", 'Bearer ' + Auth.getAccessToken())

  // REQUIRED for server side to create cache friendly self links (projections)
  authenticatedRequest.headers.append("Ketting", '1')
  return next(authenticatedRequest)
}); 


/**
 * Decorator function for entity classes, used to specify the entity path segment.
 * @param name 
 * @returns 
 */
function Entity(name?: string, label?: String) {
  return (ctor: Function) => {
    ctor.prototype.name = name ? name : ""
    ctor.prototype.label = label ? label : ""
  }
}

/**
 * Decorator function for entity fields, used to fetch relationships of the given relName using the given link variables.  
 * @param relName relationship/link name
 * @param typeName type name of the return type
 * @param isArray true if a collection
 * @param linkVariables link variables (paging/sorting/projections etc)
 * @returns 
 */
function Association(relName : string, typeName : string, isArray : boolean = false, defaultLinkVariables? : LinkVariables) {
  return (target: {} | any, name: PropertyKey): any => {
    const descriptor = {
      get(this: any) {
        const propertyName = `__${String(name)}`;

        // shallowReactive is important, we don't any ketting related triggers
        this[propertyName] = shallowReactive(new AssociationResource(this, relName, isArray, typeName, defaultLinkVariables))
        
        return this[propertyName];
      },
      enumerable: true,
      configurable: true,
    };

    Object.defineProperty(target, name, descriptor);
  };
}

/**
 * Decorator function for entity fields, used to fetch the search resource of the given relName using the given link variables.  
 * @param relName relationship/link name
 * @param typeName type name of the return type
 * @param isArray true if a collection
 * @param linkVariables link variables (paging/sorting/projections etc)
 * @returns 
 */
 function Search(relName : string, typeName : string, isArray : boolean = false, defaultLinkVariables? : LinkVariables) {
  return (target: {} | any, name: PropertyKey): any => {
    const descriptor = {
      get(this: any) {
        const propertyName = `__${String(name)}`;

        // fetch search resource from collection resource
        const searchResource = new classMapping[typeName]("search")

        this[propertyName] = new AssociationResource(searchResource, relName, isArray, typeName, defaultLinkVariables)
        
        return this[propertyName];
      },
      enumerable: true,
      configurable: true,
    };

    Object.defineProperty(target, name, descriptor);
  };
}

/**
 * Interface for all entity resources
 */
interface IHateoasResource {
    get(useCache : boolean) : Promise<State<any>> 
    data() : any
    projectionName : string | null
    isProjection : boolean
    fetch(useCache : boolean) : Promise<any>
}

/**
 * Represents an Entity association resource
 */
class AssociationResource<Type> implements IHateoasResource {
  isArray : boolean
  typeName : any
  relName : string
  defaultLinkVariables? : LinkVariables
  parentEntityResource! : EntityResource
  associationResource! : Resource
  //state : any
  values : any|any[]
  associationsLength : number = 0
  projectionName !: string

  constructor(parentEntityResource : EntityResource, relName : string, isArray : boolean, typeName : string, defaultLinkVariables? : LinkVariables) {
    this.parentEntityResource = parentEntityResource.isProjection ? parentEntityResource.fullResource() : parentEntityResource
    this.isArray = isArray
    this.typeName = typeName
    this.relName = relName
    this.defaultLinkVariables = defaultLinkVariables
    this.projectionName = defaultLinkVariables && defaultLinkVariables['projection'] ? "" + defaultLinkVariables['projection'] : ""
  }

  public get isProjection() : boolean {
    return !!this.projectionName
  }

  /**
   * Fetches the state for the association resource (not the parent resource state).
   * @returns 
   */
   async get(useCache : boolean = true) : Promise<State<any>> {
    if (this.associationResource === undefined) {
      throw new Error("getAssociation() for " + this.relName + " must be called before retrieving association state.")
    }
    let state
    if (useCache) {
      // we will return the cached version
      state = await this.associationResource.get()
    } else {
      // update the cache
      state = await this.associationResource.refresh()
      this.associationResource.updateCache(state)
    }

    return state
  }

  /**
   * Returns the cached state for this resource
   * @returns 
   */
  data() : any {
    if (this.associationResource === undefined) {
      throw new Error("getAssociation() for " + this.relName + " must be called before retrieving data: " + this.parentEntityResource.resource.uri);
    }
    return this.associationResource.getCache()?.data
  }

  async fetch(useCache : boolean) {
    return this.getAssociation({}, useCache);
  }

  /**
   * Returns EntityResource instances of the specified type.  
   * @param useCache 
   * @returns 
   */
  async getAssociation(linkVariables? : LinkVariables, useCache : boolean = true) : Promise<Type> {

    this.associationResource = await this.parentEntityResource.resource.follow(this.relName, linkVariables ? linkVariables : this.defaultLinkVariables)
    
    if (this.isArray) {
      let embeddedItemsName
      try {
        embeddedItemsName = new classMapping[this.typeName]().name
      }
      catch (e) {
        throw new Error("Can not create association for '" + this.typeName + "', no class mapping found (not defined ?)")
      }
      // TODO not sure if this will help at all, prefetch for multiple items.. but
      // all data is embedded and cached already.. also no way to trigger clearing of 
      // cache
      //this.values = (await this.associationResource.followAll(embeddedItemsName).preFetch()).map((r:any) => new classMapping[this.typeName](r))
      
      const state = await this.get(useCache)
      this.values = state.followAll(embeddedItemsName).map((r:any, idx : number) => { 
        const er = new classMapping[this.typeName](r) 
        er.idx = idx
        return er
      })
    }
    else {
      const state = await this.get(useCache)
      this.values = new classMapping[this.typeName](state.uri)
    }

    this.associationsLength = this.isArray ? this.values.length : 0

    return this.values as Type
  }

  /**
   * For array associations, returns an ordered array of the last completed call to getAssociation().  For
   * non arrays, returns the value of the last completed call as returned from the server..
   */
  orderedBy(propertyOrFunction : any, direction : "asc" | "desc", caseInsensitive : boolean = false) {
    try {
      if (typeof propertyOrFunction === "string") {
        return caseInsensitive 
              ? this.isArray ? orderBy(this.values, (value : EntityResource) => _.get(value.data(), propertyOrFunction).toLowerCase(), [direction]) : this.values
              : this.isArray ? orderBy(this.values, (value : EntityResource) => _.get(value.data(), propertyOrFunction), [direction]) : this.values
      }
      else {
        return caseInsensitive 
          ? this.isArray ? orderBy(this.values, (value : EntityResource) => propertyOrFunction(value).toLowerCase(), [direction]) : this.values
          : this.isArray ? orderBy(this.values, (value : EntityResource) => propertyOrFunction(value), [direction]) : this.values
      }
    }
    catch (e) {
      console.error("Could not sort entities by property : " + property)
      console.error(e)
      return this.values
    }
  }

  /**
   * For array associations, returns the number of associations returned from the last completed call to getAssociation().  For
   * non arrays, returns 0.
   */
  length() {
    return this.associationsLength
  }

  /**
   * Returns the associated entities, bypassing the cache (always refreshed) 
   * @param useCache 
   * @returns 
   */
   async refreshAssociation(linkVariables? : LinkVariables) : Promise<Type> {
      return this.getAssociation(linkVariables, false)
   }

  /**
   * Adds the list of items (by their resource URL) to the given association. 
   * @param itemUrls 
   * @returns 
   */
  async addTo(itemUrls : string | string[]) {
    if (this.associationResource ==  null) {
      this.associationResource = await this.parentEntityResource.resource.follow(this.relName)
    }
    if (!this.isArray && Array.isArray(itemUrls)) {
      throw new Error("This relationship is singular (not a collection), can't add multiple items.")
    }

    // convert to array, then call post for collections, put for singular
    const dataUrls = Array.isArray(itemUrls) ? itemUrls : [itemUrls]
    const dataToAdd = {data: dataUrls.join("\n"), headers: {'Content-Type': 'text/uri-list'}}

    return this.isArray 
      ? this.associationResource.post(dataToAdd)
      : this.associationResource.put(dataToAdd)
  }

  /**
   * Detaches the given item resource from this association, then performs a delete on the
   * resource.
   * @param itemResource 
   */
  async deleteFrom(itemResource : EntityResource) {
    await this.detachFrom(itemResource)
    await itemResource.delete()
  }

  /**
   * Detaches the given item resource from this associations (no delete is performed).
   * @param itemResource 
   */
  async detachFrom(itemResource : EntityResource) {
    if (!this.associationResource) {
      this.associationResource = await this.parentEntityResource.resource.follow(this.relName)
      //throw Error("Association resource must be initialized via getAssociation() before detachFrom() can be called.")
    }
    // load if not loaded
    if (!itemResource.loaded) {
      await itemResource.get()
    }
    const mrpath = this.associationResource.uri + "/" + itemResource.data().id
    const mr = new EntityResource(mrpath)
    await mr.delete()
  }

  /**
   * Unbinds this assocaition from the parent.
   * @returns 
   */
  async delete() : Promise<void> {
    if (this.associationResource ==  null) {
      this.associationResource = await this.parentEntityResource.resource.follow(this.relName)
    }
    return this.associationResource.delete()
  }
}

/**
 * Base class for all entity resources.
 */
class EntityResource implements IHateoasResource {

  name : any
  label : any
  resource! : Resource
  uriFull !: string
  idx : number = 0
  projectionName : string | null = null

  constructor(idOrResource? : string | Resource, noCache : boolean = false) {
      if (idOrResource && typeof idOrResource === "object") { // null is an object
        this.resource = idOrResource
      }
      else if (idOrResource && typeof idOrResource === "string" && idOrResource.startsWith("http")) {
        this.resource = client.go(idOrResource)
      }
      else {
        const resourcePath = process.env.VUE_APP_API_BASE_URL + "/" + (this.name ? this.name + "/" : "") + (idOrResource ? idOrResource : "")
        this.resource = client.go(resourcePath)
      }

      if (noCache) {
        Vue.prototype.$log.debug("CLEARING CACHE FOR : " + this.resource.uri)
        this.resource.clearCache()
      }

      const uriParams = new URLSearchParams(new URL(this.resource.uri).search);
      this.projectionName = uriParams.get("projection")
      const rUri = this.resource.uri

      // self uri may have {?projection} in the URL, test for that before splitting
      this.uriFull = EntityResource.fullUri(rUri)
  }

  public static fullUri(uri : string) {
    if (uri.indexOf("{?") >=0) {
        return ( uri.indexOf("{?") >=0 ) ? uri.split("{?")[0] : uri.split("?")[0]
    }
    return ( uri.indexOf("%7B?") >=0 ) ? uri.split("%7B?")[0] : uri.split("?")[0]
  }

  public get isProjection() {
    return !!this.projectionName
  }

  get sortField() : string {
    return "lastModifiedDate"
  }

  get sortDirection() : "asc" | "desc" {
    return "desc"
  }

  /**
   * If this resource represents a projection, returns a resource to the full representation, else returns
   * this.
   * @returns 
   */
  fullResource() : this {
    
    // TODO is this going to work for those 1-1 situations where URI is rel /api/A/X/B/Y ?
    return new (this.constructor as new (idOrResource : string | Resource) => this)(this.uriFull)
    //if (!this.data()) throw Error("Data has not yet been fetched for this resource: " + this.resource.uri)
    //if (!this.data().id) throw new Error("This resource has no identifier, no full resource available." + this.resource.uri)
    //return this.isProjection 
    //  ? new (this.constructor as new (idOrResource : string | Resource) => this)(this.data().id)
    //  : this
  }

  /**
   * For those entities having a collection resource, returns one.
   * @returns 
   */
  collectionResource() : this {
    return new (this.constructor as new (idOrResource : string | Resource) => this)(this.name)
  }

  get loaded() {
    return this.resource.getCache() != null
  }

  private getCachedData() : State<any> {
    const state = this.resource.getCache()
    if (state === null) {
      throw new Error("State has not been fetched, call get() first on this resource '" + this.resource.uri + "'.")
    }
    return state
  }

  /**
   * Returns the cached state for this resource
   * @returns 
   */
  data() : any {
    return this.getCachedData().data
  }

  async fetch(useCache : boolean) {
    return this.get(useCache);
  }

  /**
   * For use with item resources, fetches the state for the resource.
   * @returns 
   */
  async get(useCache : boolean = true) : Promise<State<any>> {
    return useCache ? this.resource.get() : this.resource.refresh()
  }

  /**
   * Returns the action instance from the resource state object.   Note: get() must have
   * been called prior to calling this method as we rely on the cached state to fetch
   * the actions.
   * @param action 
   * @returns 
   */
  action(action:string) : Action<any> {
    return this.getCachedData().action(action)
  } 

  /**
   * Performs an update on a single entity field via the application/merge-patch+json format.
   * @param field 
   * @param value 
   * @returns 
   */
  async mergePatch(patch : any) : Promise<State<any> | undefined> {

    const options : PatchRequestOptions = {
      headers : {'Content-Type': 'application/merge-patch+json'},
      data : patch
    }
    const state = await this.resource.patch(options)
    if (state) this.resource.updateCache(state)

    return state
  }

  /**
   * Performs a post using the given data on this resource.
   * @param obj 
   * @returns 
   */
  async post(obj : any) {
    // TODO postFollow ? https://github.com/badgateway/ketting/blob/main/src/resource.ts#L201

    // posting will always generate a 201 from the server, so follow that so we cache the
    // results.

    // TODO should we just set this resource as our new resource ?
    const resource = await this.resource.postFollow({data:obj})
    return resource.get()
  }

  /**
   * Performs a post using the given data on this resource without any 201 following
   * @param obj 
   * @returns 
   */
    async postNoFollow(obj : any) {
      return await this.resource.post({data:obj})
    }

  /**
   * Performs a put using the given data on this resource.
   * @param obj 
   * @returns 
   */
  async put(obj : any) {
    await this.resource.put({data:obj})
    return this.resource.getCache()
  }


  async delete() : Promise<void> {
    return this.resource.delete()
  }

  async deleteSilent() {
    try {
      await this.resource.delete()
    }
    catch (e:any) {
      // ignore
    }
  }
}

@Entity()
class ApiResource extends EntityResource {
  @Association("clients", "ClientResource", true)
  clients! : AssociationResource<ClientResource[]>

  @Association("contacts", "ContactResource", true)
  contacts! : AssociationResource<ContactResource[]>

  @Association("users", "UserResource", true)
  users! : AssociationResource<UserResource[]>

  @Association("properties", "PropertyResource", true)
  properties! : AssociationResource<PropertyResource[]>

  @Association("systemMediaUploads", "SystemMediaUploadResource", true)
  systemMediaUploads! : AssociationResource<SystemMediaUploadResource[]>

  @Association("maintenanceSystems", "MaintenanceSystemsResource", true)
  maintenanceSystems! : AssociationResource<MaintenanceSystemsResource[]>

  @Association("appointments", "AppointmentResource", true)
  appointments! : AssociationResource<AppointmentResource[]>

  @Association("serviceLoopTemplates", "ServiceLoopTemplateResource", true)
  serviceLoopTemplates! : AssociationResource<ServiceLoopTemplateResource[]>

  @Association("jobTemplates", "JobTemplateResource", true)
  jobTemplates! : AssociationResource<JobTemplateResource[]>

  @Association("maintenanceItemTypeSchemas", "MaintenanceItemTypeSchemaResource", true)
  maintenanceItemTypeSchemas! : AssociationResource<MaintenanceItemTypeSchemaResource[]>

  @Association("schemaPresetKeyValues", "SchemaPresetKeyValueResource", true)
  schemaPresetKeyValues! : AssociationResource<SchemaPresetKeyValueResource[]>

  @Association("schemaPresetModels", "SchemaPresetModelResource", true)
  schemaPresetModels! : AssociationResource<SchemaPresetModelResource[]>

  @Association("maintenanceItemTypes", "MaintenanceItemTypeResource", true)
  maintenanceItemTypes! : AssociationResource<MaintenanceItemTypeResource[]>

  @Association("maintenanceItemPrototypes", "MaintenanceItemPrototypeResource", true)
  maintenanceItemPrototypes! : AssociationResource<MaintenanceItemPrototypeResource[]>

  @Association("propertyMaintenanceSystems", "PropertyMaintenanceSystemsResource", true)
  propertyMaintenanceSystems! : AssociationResource<PropertyMaintenanceSystemsResource[]>
  
  private static _instance: ApiResource
  public static get Instance()
  {
      return this._instance || (this._instance = new this());
  }
}

@Entity("settings")
class SettingsResource extends EntityResource {
  
  public static SETTING_APPOINTMENT_LEAD = "scheduling.appointment.lead"
  public static SETTING_JOB_GROUPING_DAYS = "jobGroupingInDays"
  
  private static _instance: SettingsResource
  private static _defaultsInstance: SettingsResource
  public static get Instance()
  {
      return this._instance || (this._instance = new this());
  }
  public static get DefaultsInstance()
  {
      return this._defaultsInstance || (this._defaultsInstance = new this("defaults"));
  }

  static defaultObject(name : string) : any {
    if (!SettingsResource.defaultSetting("defaultObjectValues")[name]) {
      throw new Error("No default object defined for '" + name + "'.  Called too early ?")
    }
    return SettingsResource.defaultSetting("defaultObjectValues")[name]
  }

  static defaultSetting(key : string) {
    return this._defaultsInstance.data()[key]
  }

  static setting(key: string) {
    // assumes all settings have been loaded
    return new SettingsResource(SettingsResource.domain + "-" + key).data().value
  }

  static getSettingResource(key: string) {
    // assumes all settings have been loaded
    return new SettingsResource(SettingsResource.domain + "-" + key)
  }

  static get domain() {
    return SettingsResource.defaultSetting("googleDomain")
  }

  static getCalendarColor(id : string) {
    return SettingsResource.calendarColors.find((ec:any) => ec.id == id)
  }

  static get calendarColors() {
    return this.sortColors(SettingsResource.defaultSetting("googleCalendarColors"))
  }

  static getCalendarEventColor(id : string) {
    return SettingsResource.calendarEventColors.find((ec:any) => ec.id == id)
  }

  static get calendarEventColors() : any {
    return this.sortColors(SettingsResource.defaultSetting("googleCalendarEventColors"))
  }

  private static sortColors(colors : any[]) {
    return sortBy(colors, function (c) {
        return parseInt(c.id, 10);
    });
  }
}

@Entity("workflow", "Workflow")
class WorkflowResource extends EntityResource {
}

@Entity("users", "User")
class UserResource extends EntityResource {
  @Association("calendars", "CalendarResource", true)
  calendars! : AssociationResource<CalendarResource[]>

  @Association("settings", "UserSettingsResource")
  settings ! : AssociationResource<UserSettingsResource[]>
}

@Entity("userSettings", "User settings")
class UserSettingsResource extends EntityResource {
}

@Entity("calendars", "Calendar")
class CalendarResource extends EntityResource {
}

@Entity("datamigrations")
class MigrationStatusResource extends EntityResource {
}

@Entity("maintenanceSystems", "Maintenance system")
class MaintenanceSystemsResource extends EntityResource {
  @Association("jobTemplates", "JobTemplateResource", true)
  jobTemplates! : AssociationResource<JobTemplateResource[]>

  @Association("serviceLoopTemplates", "ServiceLoopTemplateResource", true)
  serviceLoopTemplates! : AssociationResource<ServiceLoopTemplateResource[]>

  @Association("maintenanceItemTypes", "MaintenanceItemTypeResource", true)
  maintenanceItemTypes! : AssociationResource<MaintenanceItemTypeResource[]>

  @Association("maintenanceItemTypesRolledUp", "MaintenanceItemTypeResource", true)
  maintenanceItemTypesRolledUp! : AssociationResource<MaintenanceItemTypeResource[]>

  @Association("maintenanceItemPrototypesRolledUp", "MaintenanceItemPrototypeResource", true)
  maintenanceItemPrototypesRolledUp! : AssociationResource<MaintenanceItemPrototypeResource[]>

  @Association("maintenanceItemPackages", "MaintenanceItemPackageResource", true)
  maintenanceItemPackages! : AssociationResource<MaintenanceItemPackageResource[]>

  @Association("maintenanceItemPackagesRolledUp", "MaintenanceItemPackageResource", true)
  maintenanceItemPackagesRolledUp! : AssociationResource<MaintenanceItemPackageResource[]>

  @Association("serviceLoopTemplatesRolledUp", "ServiceLoopTemplateResource", true)
  serviceLoopTemplatesRolledUp! : AssociationResource<ServiceLoopTemplateResource[]>

  @Association("jobTemplatesRolledUp", "JobTemplateResource", true)
  jobTemplatesRolledUp! : AssociationResource<JobTemplateResource[]>

  @Association("subsystems", "MaintenanceSystemsResource", true)
  subsystems ! : AssociationResource<MaintenanceSystemsResource[]>

  @Search("root", "MaintenanceSystemsResource")
  root! : AssociationResource<MaintenanceSystemsResource>

  /**
   * Locates a maintenance system with the given id within the given maintenance system tree node (as returned by
   * the root search association).
   * @param system 
   * @param msId 
   * @returns 
   */
   public static findChildSystem(systemNode : any, msId : any) : any{
    if (systemNode.id == msId) {
      return systemNode
    }
    if (systemNode.subsystems) {
      for (const subsystem of systemNode.subsystems) {
        const foundSystem = this.findChildSystem(subsystem, msId)
        if (foundSystem) return foundSystem
      }
    }
    return false
  }
}

@Entity("meta/forms")
class FormsMetadataResource extends EntityResource { 

  private static _instance: FormsMetadataResource
  public static get Instance()
  {
      return this._instance || (this._instance = new this());
  }

  /**
   * Returns the field metadata from the given form field.
   * @param formname 
   * @param fieldname 
   * @returns 
   */
  getHalField(formname : string, fieldname : string) : Field | undefined {
    try {
      const halAction = this.action(formname)
      const halFields = halAction.fields
      return halFields.find(f => f.name === fieldname)
    } catch (error) {
      console.error(error)
      throw new Error("Error fetching field '" + fieldname + "' for form '" + formname + "': " + error);
    }
  }
  
  /**
   * Returns an array of option objects (key/value) for the field from the from with the given names.
   * @param formname 
   * @param fieldname 
   * @returns 
   */
  getHalOptions(formname : string, fieldname : string) {
    const optionsField  = this.getHalField(formname, fieldname) as any
    const options = optionsField?.options

    if (options && Object.keys(options).length > 0) {
      const remapped : any[] = []
      Object.keys(options).map((key) => {
          remapped.push({text : options[key], value : key})
      })
     return remapped
    }
    return []
  }
}

function getEnumText(formName: string, key: string, value : any) {
  const formsMetadataResource = new FormsMetadataResource()
  const action : Action<any> | undefined = formsMetadataResource.action(formName)

  if (action === undefined) {
    throw new Error("No form metadata found for form '" + key + "'.");
  }
  const optionsField : any = formsMetadataResource.getHalField(formName, key)
  return optionsField.options[value]
}

@Entity("contacts", "Contact")
class ContactResource extends EntityResource { 

    /**
    * Formats this contacts name for display
    * @param useLastname 
    * @returns 
    */
    static getContactName(contact : any, useLastname = true) {
      if (contact.name) {
          return contact.name.trim()
      }
      return ((contact.title ? getEnumText("contact", "title", contact.title) + " " : "") +
            (contact.nickname ? contact.nickname : (contact.firstname ? contact.firstname : "")) +
            (useLastname ? " " + (contact.lastname ? contact.lastname : "") : "")).trim();
    }
}
export type CONTACT_TYPES = "PRIMARY" | "SECONDARY" | "ALTERNATIVE" | "TENANT" | "BUSINESS" | "INVOICING"

@Entity("maintenanceLogs", "Maintenance log")
class MaintenanceLogResource extends EntityResource {

  @Association("propertyMaintenanceSystem", "PropertyMaintenanceSystemsResource", false)
  propertyMaintenanceSystem! : AssociationResource<PropertyMaintenanceSystemsResource>

  @Association("job", "MaintenanceJobResource", false)
  job! : AssociationResource<MaintenanceJobResource>

  @Association("media", "SystemMediaResource", true)
  media! : AssociationResource<SystemMediaResource[]>

  @Association("mediaAccepted", "SystemMediaResource", true)
  mediaAccepted! : AssociationResource<SystemMediaResource[]>

  get sortField() : string {
    return "createdDate"
  }

}

@Entity("appointments", "Appointments")
class AppointmentResource extends EntityResource {

  public static STATUS_DRAFT        = "DRAFT";
  public static STATUS_PENDING      = "PENDING";
  public static STATUS_IN_PROGRESS  = "IN_PROGRESS";
  public static STATUS_ON_HOLD      = "ON_HOLD";
  public static STATUS_COMPLETED    = "COMPLETED";
  public static STATUS_CONFIRMED    = "CONFIRMED";
  public static STATUS_CANCELLED    = "CANCELLED";
  public static STATUSES_FINISHED   = [AppointmentResource.STATUS_CANCELLED, AppointmentResource.STATUS_COMPLETED]

  @Association("jobs", "MaintenanceJobResource", true)
  jobs! : AssociationResource<MaintenanceJobResource[]>

  @Search("findByStartDateBetween", "AppointmentResource", true)
  searchByStartDateBetween! : AssociationResource<AppointmentResource[]>

  get sortField() : string {
    return "startDate"
  }

  static getDefaultStatusBackgroundColor(statusValue : string) {
    switch (statusValue) {
      case this.STATUS_DRAFT: return 'warning';
      case this.STATUS_PENDING: return 'warning';
      case this.STATUS_CONFIRMED: return 'success';
      case this.STATUS_IN_PROGRESS: return 'success';
      case this.STATUS_ON_HOLD: return 'warning';
      case this.STATUS_COMPLETED: return 'black';
      case this.STATUS_CANCELLED: return 'error';
      default: return 'black'
    }
  }
  static getDefaultStatusForegroundColor(statusValue : string) {
    switch (statusValue) {
      case this.STATUS_DRAFT: return 'black--text';
      case this.STATUS_PENDING: return 'black--text';
      case this.STATUS_CONFIRMED: return 'white--text';
      case this.STATUS_IN_PROGRESS: return 'white--text';
      case this.STATUS_ON_HOLD: return 'black--text';
      case this.STATUS_COMPLETED: return 'white--text';
      case this.STATUS_CANCELLED: return 'white--text';
      default: return 'black--text'
    }
  }

  get defaultBackgroundColor() {
    return AppointmentResource.getDefaultStatusBackgroundColor(this.data().status)
  }

  get defaultForegroundColor() {
    return AppointmentResource.getDefaultStatusForegroundColor(this.data().status)
  }

  static getStatusColor(statusValue : string) {
    const statusColorId = statusValue ? SettingsResource.setting("scheduling.appointment." + statusValue + ".color") : undefined
    return statusColorId ? SettingsResource.getCalendarEventColor(statusColorId) : undefined
  }

  get statusColor() {
    return AppointmentResource.getStatusColor(this.data().status)
  }

  get foregroundColor() {
    const c = this.statusColor
    return c ? c.foreground : undefined
  }
  get backgroundColor() {
    const c = this.statusColor
    return c ? c.background : undefined
  }

}

@Entity("serviceLoops", "Service loop")
class ServiceLoopResource extends EntityResource {

  @Association("propertyMaintenanceSystem", "PropertyMaintenanceSystemsResource", false)
  propertyMaintenanceSystem! : AssociationResource<PropertyMaintenanceSystemsResource>
}

@Entity("serviceLoopTemplates", "Service loop template")
class ServiceLoopTemplateResource extends EntityResource {
}

@Entity("schemaPresetKeyValues", "Schema preset key/value")
class SchemaPresetKeyValueResource extends EntityResource {

  @Search("keyAndType", "SchemaPresetKeyValueResource", true)
  searchByKeyAndType! : AssociationResource<SchemaPresetKeyValueResource[]>
}

@Entity("schemaPresetModels", "Schema preset models")
class SchemaPresetModelResource extends EntityResource {

  @Search("type", "SchemaPresetModelResource", true)
  searchByType! : AssociationResource<SchemaPresetModelResource[]>

  @Search("typeAndName", "SchemaPresetModelResource", true)
  searchByTypeAndName! : AssociationResource<SchemaPresetModelResource[]>
}


@Entity("maintenanceItemTypes", "Maintenance item type")
class MaintenanceItemTypeResource extends EntityResource {

  @Association("maintenanceSystems", "MaintenanceSystemsResource", true)
  maintenanceSystems! : AssociationResource<MaintenanceSystemsResource[]>

  @Association("schemaVersions", "MaintenanceItemTypeSchemaResource", true)
  schemaVersions! : AssociationResource<MaintenanceItemTypeSchemaResource[]>

  @Association("parts", "MaintenanceItemTypeResource", true)
  parts! : AssociationResource<MaintenanceItemTypeResource[]>

  @Association("presetValues", "SchemaPresetKeyValueResource", true)
  presetValues! : AssociationResource<SchemaPresetKeyValueResource[]>

  @Association("presetModels", "SchemaPresetModelResource", true)
  presetModels! : AssociationResource<SchemaPresetModelResource[]>

  @Search("name", "MaintenanceItemTypeResource", true)
  searchByName! : AssociationResource<MaintenanceItemTypeResource[]>
  
}

const SCHEMA_X_PROTOTYPE_PROPERTY_PROP = "x-is-prototype-property"
const SCHEMA_X_PRESETS_PROP = "x-presets"

@Entity("maintenanceItemTypeSchemas", "Maintenance item type schema")
class MaintenanceItemTypeSchemaResource extends EntityResource {

  @Association("maintenanceItemType", "MaintenanceItemTypeResource", false)
  maintenanceItemType! : AssociationResource<MaintenanceItemTypeResource>

  /**
   * Returns true if a property with the given name is a prototype property.  That is any field with
   * the X_PROTOTYPE_PROPERTY_PROP value set to true, or any field that is part of a preset property with
   * X_PROTOTYPE_PROPERTY_PROP set to true.
   * @param name 
   * @param jsonSchema 
   * @returns 
   */
  public static isPrototypeOnlyProperty(name : string, jsonSchema : any) {
    
    // does the property have the SCHEMA_X_PROTOTYPE_PROPERTY_PROP set ?
    if (name == 'name' || jsonSchema.properties[name][SCHEMA_X_PROTOTYPE_PROPERTY_PROP]) {
      return true
    }

    // check if the property name is the name of a field making up a prototype
    // property that does have the flag set.
    if (jsonSchema[SCHEMA_X_PRESETS_PROP]) {
      for (const [key, value] of Object.entries(jsonSchema[SCHEMA_X_PRESETS_PROP])) {
        
        // if the field is a preset field, check if preset has the prop set
        // @ts-ignore
        if (value.includes(name)) {
          return key == 'name' || jsonSchema.properties[key][SCHEMA_X_PROTOTYPE_PROPERTY_PROP]
        }
      }

    }
    return false
  }

  /**
   * Returns true if the given property name is a preset property
   * @param pName 
   * @param jsonSchema 
   * @returns 
   */
  public static isPresetProperty(pName : string, jsonSchema : any) {
    return jsonSchema[SCHEMA_X_PRESETS_PROP] && jsonSchema[SCHEMA_X_PRESETS_PROP][pName]
  }

  /**
   * Returns true if the given property name is the field making up a preset property.
   * @param pName 
   * @param jsonSchema 
   * @returns 
   */
    public static isPresetFieldProperty(pName : string, jsonSchema : any) {
      if (jsonSchema[SCHEMA_X_PRESETS_PROP]) {
        for (const pNames of Object.values(jsonSchema[SCHEMA_X_PRESETS_PROP])) {
          // if the field is a preset field, check if preset has the prop set
          // @ts-ignore
          if (pNames.includes(pName)) {
            return true
          }
        }
      }
      return false
    }

  /**
   * Returns an object keyed by property name, where the value is the property settings for properties making up the given
   * preset property, or an empty object.
   * @param pName 
   * @param jsonSchema 
   * @returns 
   */
    public static getPresetPropertyFields(pName : string, jsonSchema : any) : {} {
      if (!MaintenanceItemTypeSchemaResource.isPresetProperty(pName, jsonSchema)) {
        return {}
      }
      const fields : { [key: string]: any } = {}
      for (const propName of jsonSchema[SCHEMA_X_PRESETS_PROP][pName]) {
        fields[propName] = jsonSchema.properties[propName]
      }
      return fields
    }

  /**
   * Filters out the model properties from the given model.  If prototypeOnly is set,
   * then only the prototype model properties are returned, else the non prototype model properties 
   * are returned.
   * @param model 
   * @param prototypeOnly 
   * @returns 
   */
  public static filterModelProperties(model : any, jsonSchema : any, prototypeOnly : boolean) {
    const filteredModel = {...model}

    // if filteirng prototypes, remove those with the prop or the name prop, else
    // rmeove all others
    for (const [key, value] of Object.entries(jsonSchema.properties)) {
      if (prototypeOnly && MaintenanceItemTypeSchemaResource.isPrototypeOnlyProperty(key, jsonSchema)) {
        delete filteredModel[key]
      } else {
        delete filteredModel[key]
      }
    }

    return filteredModel
  }

  /**
   * Filters out the schema properties from the given jsonSchema.  If prototypeOnly is set,
   * then only the prototype properties are returned, else the non prototype properties 
   * are returned.
   * @param jsonSchema 
   * @param prototypeOnly 
   * @returns 
   */
  public static filterSchemaProperties(jsonSchema : any, prototypeOnly : boolean) {
    const filteredSchema = {...jsonSchema}

    // if filtering prototypes, remove those with the prop or the name prop, else
    // remove all others
    for (const [key, value] of Object.entries(jsonSchema.properties)) {

      const isPrototypeProperty = MaintenanceItemTypeSchemaResource.isPrototypeOnlyProperty(key, jsonSchema)

      if (prototypeOnly && !isPrototypeProperty) {
        delete filteredSchema.properties[key]
      }
      else if (!prototypeOnly && isPrototypeProperty) {
        delete filteredSchema.properties[key]
      }
    }

    return filteredSchema
  }
}

@Entity("maintenanceItemPrototypes", "Maintenance item prototype")
class MaintenanceItemPrototypeResource extends EntityResource {

  @Association("maintenanceSystems", "MaintenanceSystemsResource", true)
  maintenanceSystems! : AssociationResource<MaintenanceSystemsResource[]>

  @Association("maintenanceItemType", "MaintenanceItemTypeResource", false)
  maintenanceItemType! : AssociationResource<MaintenanceItemTypeResource>

  @Association("maintenanceItemTypeSchema", "MaintenanceItemTypeSchemaResource", false)
  maintenanceItemTypeSchema! : AssociationResource<MaintenanceItemTypeSchemaResource>

  @Association("parts", "MaintenanceItemPrototypeResource", true)
  parts! : AssociationResource<MaintenanceItemPrototypeResource[]>

  @Search("name", "MaintenanceItemPrototypeResource", true)
  searchByName! : AssociationResource<MaintenanceItemPrototypeResource[]>

  @Search("type", "MaintenanceItemPrototypeResource", true)
  searchByType! : AssociationResource<MaintenanceItemPrototypeResource[]>

  @Search("nameAndType", "MaintenanceItemPrototypeResource", true)
  searchByNameAndType! : AssociationResource<MaintenanceItemPrototypeResource[]>
  
}

/**
 * A collection/package of maintenance items.  Used to add a group of maintenance items to a property maintenance system.
 */
@Entity("maintenanceItemPackages", "Maintenance item package")
class MaintenanceItemPackageResource extends EntityResource {

  @Association("items", "MaintenanceItemPrototypeResource", true)
  items! : AssociationResource<MaintenanceItemPrototypeResource[]>
}

/**
 * Maintenance items that belong to property maintenance systems
 */
@Entity("propertyMaintenanceItems", "Property maintenance item")
class PropertyMaintenanceItemResource extends EntityResource {

  @Association("propertyMaintenanceSystem", "PropertyMaintenanceSystemsResource", false)
  propertyMaintenanceSystem! : AssociationResource<PropertyMaintenanceSystemsResource>

  @Association("prototype", "MaintenanceItemPrototypeResource", false)
  prototype! : AssociationResource<MaintenanceItemPrototypeResource>

  @Association("parts", "PropertyMaintenanceItemResource", true)
  parts! : AssociationResource<PropertyMaintenanceItemResource[]>

  @Association("media", "SystemMediaResource", true)
  media! : AssociationResource<SystemMediaResource[]>

  @Association("mediaAccepted", "SystemMediaResource", true)
  mediaAccepted! : AssociationResource<SystemMediaResource[]>

  @Association("parent", "PropertyMaintenanceItemResource", false)
  parent! : AssociationResource<PropertyMaintenanceItemResource>
}


@Entity("jobTemplates", "Job template")
class JobTemplateResource extends EntityResource {

  get sortField() : string {
    return "name"
  }

  static get simpleJobLabel() {
    return "Simple job (no workflow)"
  }
}

@Entity("jobs", "Maintenance job")
class MaintenanceJobResource extends EntityResource {

  public static STATUS_OPEN_UNSCHEDULED="OPEN_UNSCHEDULED"
  public static STATUS_OPEN_SCHEDULED="OPEN_SCHEDULED"
  public static STATUS_CLOSED_CANCELLED="CLOSED_CANCELLED"
  public static STATUS_CLOSED_COMPLETED="CLOSED_COMPLETED"
  public static STATUS_CLOSED = [MaintenanceJobResource.STATUS_CLOSED_CANCELLED, MaintenanceJobResource.STATUS_CLOSED_COMPLETED]
  
  @Association("property", "PropertyResource", false)
  property! : AssociationResource<PropertyResource>

  @Association("propertyMaintenanceSystem", "PropertyMaintenanceSystemsResource", false)
  propertyMaintenanceSystem! : AssociationResource<PropertyMaintenanceSystemsResource>

  @Association("jobTemplate", "JobTemplateResource", false)
  jobTemplate! : AssociationResource<JobTemplateResource>

  @Association("healthReport", "HealthReportResource", false)
  healthReport! : AssociationResource<HealthReportResource>

  @Association("logs", "MaintenanceLogResource", true)
  logs! : AssociationResource<MaintenanceLogResource>

  @Association("appointments", "AppointmentResource", true)
  appointments! : AssociationResource<AppointmentResource[]>

  @Search("all", "MaintenanceJobResource", true)
  all! : AssociationResource<MaintenanceJobResource[]>

  @Search("allUnpaged", "MaintenanceJobResource", true)
  allUnpaged! : AssociationResource<MaintenanceJobResource[]>

  @Search("allLimitOffset", "MaintenanceJobResource", true)
  allLimitOffset! : AssociationResource<MaintenanceJobResource[]>
  
  @Search("openJobsWithSameRecurrence", "MaintenanceJobResource", true)
  openJobsWithSameRecurrence! : AssociationResource<MaintenanceJobResource[]>

  @Association("relatedMedia", "SystemMediaResource", true)
  relatedMedia! : AssociationResource<SystemMediaResource[]>

  static SEARCH_RESOURCE = new MaintenanceJobResource("jobs/search")

  get sortField() : string {
    return "startDate"
  }

  /**
   * Returns the next date in the sequence given the recurrence rule and start date.
   * @param rrule 
   * @param startDate 
   * @returns 
   */
   static async nextdate(rrule : string, startDate : string, count : string | number) : Promise<State> {
    const resultResource = await MaintenanceJobResource.SEARCH_RESOURCE.resource.follow("nextdate", {startDate:startDate, rrule:rrule, count: count})
    return resultResource.get()
  }
}

@Entity("healthReports", "Health report")
class HealthReportResource extends EntityResource {

  @Association("media", "SystemMediaResource", true)
  media! : AssociationResource<SystemMediaResource[]>

  @Association("mediaAccepted", "SystemMediaResource", true)
  mediaAccepted! : AssociationResource<SystemMediaResource[]>

  @Association("propertyMaintenanceSystem", "PropertyMaintenanceSystemsResource", false)
  propertyMaintenanceSystem! : AssociationResource<PropertyMaintenanceSystemsResource>

  @Association("job", "MaintenanceJobResource", false)
  job! : AssociationResource<MaintenanceJobResource>

  @Search("findPropertiesWithIssues", "HealthReportResource", true)
  propertiesWithIssues! : AssociationResource<HealthReportResource[]>

  static SERIOUSNESS = ["WARNING", "CRITICAL"]
  static isSerious(status : string) {
    return HealthReportResource.SERIOUSNESS.includes(status)
  }

  static colorForStatus(status : any) {
    switch (status) {
      case "OK" : return "success"
      case "WARNING" : return "warning"
      case "CRITICAL" : return "error"
      default : return "grey darken-1"
    }
  }

  get sortField() : string {
    return "reportDate"
  }
}

@Entity("propertyMaintenanceSystems", "Property maintenance system")
class PropertyMaintenanceSystemsResource extends EntityResource {

  @Association("media", "SystemMediaResource", true)
  media! : AssociationResource<SystemMediaResource[]>

  @Association("mediaAccepted", "SystemMediaResource", true)
  mediaAccepted! : AssociationResource<SystemMediaResource[]>

  @Association("serviceLoops", "ServiceLoopResource", true)
  serviceLoops! : AssociationResource<ServiceLoopResource[]>

  @Association("maintenanceItems", "PropertyMaintenanceItemResource", true)
  maintenanceItems! : AssociationResource<PropertyMaintenanceItemResource[]>

  @Association("healthReports", "HealthReportResource", true)
  healthReports! : AssociationResource<HealthReportResource[]>

  @Association("jobs", "MaintenanceJobResource", true)
  jobs! : AssociationResource<MaintenanceJobResource[]>

  @Association("jobsRolledUp", "MaintenanceJobResource", true)
  jobsRolledUp! : AssociationResource<MaintenanceJobResource[]>

  @Association("logsRolledUp", "MaintenanceLogResource", true)
  logsRolledUp! : AssociationResource<MaintenanceLogResource[]>

  @Association("healthSummary", "HealthReportResource", false)
  healthSummary! : AssociationResource<HealthReportResource>

  @Association("healthSummariesRolledUp", "HealthReportResource", true)
  healthSummariesRolledUp! : AssociationResource<HealthReportResource[]>
  
  @Association("mediaRolledUp", "SystemMediaResource", true)
  mediaRolledUp! : AssociationResource<SystemMediaResource[]>

  @Association("serviceLoopsRolledUp", "ServiceLoopResource", true)
  serviceLoopsRolledUp! : AssociationResource<ServiceLoopResource[]>

  @Association("maintenanceItemsRolledUp", "PropertyMaintenanceItemResource", true)
  maintenanceItemsRolledUp! : AssociationResource<PropertyMaintenanceItemResource[]>

  @Association("healthReportsRolledUp", "HealthReportResource", true)
  healthReportsRolledUp! : AssociationResource<HealthReportResource[]>

  @Association("availableRolledUpJobTemplates", "JobTemplateResource", true)
  availableRolledUpJobTemplates! : AssociationResource<JobTemplateResource[]>

  @Association("maintenanceSystem", "MaintenanceSystemsResource", false)
  maintenanceSystem! : AssociationResource<MaintenanceSystemsResource>

  @Association("property", "PropertyResource", false)
  property! : AssociationResource<PropertyResource>

  @Search("primarySystem", "PropertyMaintenanceSystemsResource", false)
  searchPrimarySystem! : AssociationResource<PropertyMaintenanceSystemsResource>

  @Search("sameMaintenanceSystem", "PropertyMaintenanceSystemsResource", true)
  sameMaintenanceSystem! : AssociationResource<PropertyMaintenanceSystemsResource[]>

  /**
   * Returns all PMS that match the given maintenance system id for the property with the given id.   There may be multiple
   * PMS for a given property that share the same MS.
   * @param pid 
   * @param msid 
   * @returns 
   */
  public static async searchForMultiple(pid : any, msid : any) {
      // TODO Some duplication with the JobForm... we have a selector there to disambiguate using the same associationresource and params
      const params = {pid: pid, msid: msid, projection : "propertyMaintenanceSystemSummary"};
      return (await new PropertyMaintenanceSystemsResource().sameMaintenanceSystem.getAssociation(params, false))
  }

  /**
   * Locates a property maintenance system with the given id within the given property maintenance system tree node.
   * @param system 
   * @param pmsId 
   * @returns 
   */
  public static findChildSystem(systemNode : any, pmsId : any) : any{
    if (systemNode.id == pmsId) {
      return systemNode
    }
    if (systemNode.subsystems) {
      for (const subsystem of systemNode.subsystems) {
        const foundSystem = this.findChildSystem(subsystem, pmsId)
        if (foundSystem) return foundSystem
      }
    }
    return false
  }

  /**
   * Locates a property maintenance system with a maintenance system matching the given id within the given property maintenance system tree node.
   * @param system 
   * @param pmsId 
   * @returns 
   */
  public static findChildSystemByMaintenanceSystemId(systemNode : any, msId : any) : any{
      if (systemNode.maintenanceSystemId == msId) {
        return systemNode
      }
      if (systemNode.subsystems) {
        for (const subsystem of systemNode.subsystems) {
          const foundSystem = this.findChildSystemByMaintenanceSystemId(subsystem, msId)
          if (foundSystem) return foundSystem
        }
      }
      return false
  }

  /**
   * Returns the top level subsystem for the system with the given context id
   * @param pmsTree 
   * @param contextPmsId 
   * @returns 
   */
  static subsystemRoot(pmsTree : any, contextPmsId : any, test? : boolean) {
    for (const pmsSubsystem of pmsTree.subsystems) {
      const foundSubsystem = this.findChildSystem(pmsSubsystem, contextPmsId)
      
      if (foundSubsystem) {
        return pmsSubsystem
      }
    }
    return pmsTree
  }
}

@Entity("medias", "Media")
class MediaResource extends EntityResource {
  thumbnailUrl() : string  {
    return this.uriFull + "/thumbnail"
  }
  viewUrl() : string  {
    return this.uriFull + "/view"
  }
  downloadUrl() : string  {
    return this.uriFull + "/download"
  }
  isPDF() : boolean {
    return this.data().mimeType === "application/pdf"
  }

  public static get mediaUploadUrl() {
    return new MediaResource("upload").uriFull
  }
}

@Entity("systemMediaUploads", "System media upload")
class SystemMediaUploadResource extends EntityResource {
  
  @Association("media", "SystemMediaResource", true)
  media! : AssociationResource<SystemMediaResource[]>
}


@Entity("systemMedias", "System media")
class SystemMediaResource extends MediaResource {
  
  @Association("propertyMaintenanceSystem", "PropertyMaintenanceSystemsResource", false)
  propertyMaintenanceSystem! : AssociationResource<PropertyMaintenanceSystemsResource>

  @Association("job", "MaintenanceJobResource", false)
  job! : AssociationResource<MaintenanceJobResource>

  @Association("log", "MaintenanceLogResource", false)
  log! : AssociationResource<MaintenanceLogResource>

  @Association("healthReport", "HealthReportResource", false)
  healthReport! : AssociationResource<HealthReportResource>

  @Association("maintenanceItem", "PropertyMaintenanceItemResource", false)
  maintenanceItem! : AssociationResource<PropertyMaintenanceItemResource>

  public static get mediaUploadUrl() {
    return new SystemMediaResource("upload").uriFull
  }
}


@Entity("properties", "Property")
class PropertyResource extends EntityResource {

  private static _instance: PropertyResource
  public static get Instance()
  {
      return this._instance || (this._instance = new this());
  }

  @Association("media", "MediaResource", true)
  media! : AssociationResource<MediaResource[]>

  @Association("client", "ClientResource")
  client! : AssociationResource<ClientResource>

  @Association("jobs", "MaintenanceJobResource", true)
  jobs! : AssociationResource<MaintenanceJobResource[]>

  @Association("maintenanceSystems", "PropertyMaintenanceSystemsResource", true)
  maintenanceSystems! : AssociationResource<PropertyMaintenanceSystemsResource[]>

  @Association("maintenanceSystemsTree", "PropertyMaintenanceSystemsResource")
  maintenanceSystemsTree! : AssociationResource<PropertyMaintenanceSystemsResource>

  @Association("contacts", "ContactResource", true)
  contacts! : AssociationResource<ContactResource[]>

  @Association("healthSummary", "HealthReportResource", true)
  healthSummary! : AssociationResource<HealthReportResource[]>

  @Search("addressOrName", "PropertyResource", true)
  searchByAddressOrName! : AssociationResource<PropertyResource[]>

  @Search("statistics", "PropertyResource")
  statistics! : AssociationResource<EntityResource[]>
  
  static getMaintenanceSystem(pid : string, pmsId : string) : PropertyMaintenanceSystemsResource {
    const pr = new PropertyResource(pid)
    return new PropertyMaintenanceSystemsResource(pr.resource.uri + "/maintenanceSystems/" + pmsId)
  }

  static getPropertyAddress(p : any) {
    if (!p?.address) {
        return "";
    }
    return p.address.city ? p.address.address + ", " + p.address.city : p.address.address
  }
  static getPropertyAddressNoCity(p : any) {
    if (!p?.address) {
        return "";
    }
    return p.address.address
  }
}

@Entity("clients", "Client")
class ClientResource extends EntityResource {

  @Association("contacts", "ContactResource", true)
  contacts! : AssociationResource<ContactResource[]>

  @Association("properties", "PropertyResource", true)
  properties! : AssociationResource<PropertyResource[]>

  @Association("media", "MediaResource", true)
  media! : AssociationResource<MediaResource[]>

  @Search("nameOrAddress", "ClientResource", true)
  searchByNameOrAddress! : AssociationResource<ClientResource[]>

  @Search("statistics", "ClientResource")
  statistics! : AssociationResource<EntityResource[]>

  @Association("jobs", "MaintenanceJobResource", true)
  jobs! : AssociationResource<MaintenanceJobResource[]>

  static contactsHaveSameLastName(contacts : any[]) {
    const lastname = contacts[0].lastname 
    if (!lastname) return false

    return contacts.every(c => c.lastname && c.lastname.trim() === lastname.trim())
  }

  static isBusiness(c : any) {
    return ClientResource.getContactsByType(c, ["BUSINESS"]).length >= 1
  }

  /**
   * Returns a subset of this clients contacts of the given types
   * @param {*} types 
   * @returns 
   * @todo https://aquality.atlassian.net/browse/OJAPI-34
   */
  static getContactsByType(c : any, types: CONTACT_TYPES[]) {
    const contacts = c.contacts as any[]
    return contacts.filter(c => c != null ? types.includes(c.type) : false)
  }

  /**
   * Returns business contacts
   * @returns 
   */
  static getBusinessContacts(c : any) {
    return ClientResource.getContactsByType(c, ["BUSINESS"])
  }

  /**
   * Returns main contacts (primary/secondary)
   * @returns 
   */
  static getMainContacts(c : any) {
    return ClientResource.getContactsByType(c, ["PRIMARY", "SECONDARY"])
  }

  /**
   * Extracts any primary and secondary contacts from the client, or from an array of 
   * contacts if c is an array
   * @returns 
   */
  static getClientName(c : any) {

    if (!c) {
      throw new Error("No client given to getClientName()")
    }
    
    // munge c if it is an array (assuming array of contacts)
    if (Array.isArray(c)) {
      c = {contacts: c}
    }

    // no contacts
    if (!c.contacts || c.contacts.length == 0) {
      return "No contacts available"
    }

    // business ?
    if (ClientResource.isBusiness(c)) {
      const businessContacts = ClientResource.getBusinessContacts(c)
      return businessContacts.length > 0 ? businessContacts[0].name.trim() : "No business contacts available"
    }

    // main contacts
    const mainContacts = ClientResource.getMainContacts(c)
    if (mainContacts.length == 0) {
      return "No primary/secondary contacts available"
    }

    // just one contact
    if (mainContacts.length == 1) {
      return ContactResource.getContactName(mainContacts[0])
    }

    // multiple contacts
    if (ClientResource.contactsHaveSameLastName(mainContacts)) {
      const lastname = mainContacts[0].lastname
      return (mainContacts.map(c => ContactResource.getContactName(c, false)).join(" & ") + " " + lastname).trim()
    }

    return mainContacts.map(c => ContactResource.getContactName(c)).join(" & ").trim()
  }

  /**
   * Removes any property associations this client has and then deletes the client.
   * @returns 
   * @todo handle this transactionally in service layer ?
   */
  async delete() : Promise<void> {
    // detach client properties from this client first
    const propertyAssociation = await this.properties
    const propertyResources = await propertyAssociation.getAssociation()
    
    for (let i = 0; i < propertyResources.length; i++) {
      const cp = await (await propertyResources[i].client).getAssociation()
      await cp.delete()
    }
  
    // delete this resource
    return super.delete()

  }
}


const classMapping :any = {                  SystemMediaUploadResource, SchemaPresetKeyValueResource, SchemaPresetModelResource, AppointmentResource, ServiceLoopResource, UserSettingsResource, UserResource, CalendarResource, MaintenanceJobResource, HealthReportResource, WorkflowResource, JobTemplateResource, SettingsResource, PropertyMaintenanceItemResource, MaintenanceItemPackageResource, MaintenanceItemPrototypeResource, MaintenanceItemTypeResource, MaintenanceItemTypeSchemaResource, ServiceLoopTemplateResource, MaintenanceLogResource, MaintenanceSystemsResource, MediaResource, SystemMediaResource, ContactResource, ClientResource, PropertyResource, PropertyMaintenanceSystemsResource}
export                    {IHateoasResource, SystemMediaUploadResource, SchemaPresetKeyValueResource, SchemaPresetModelResource, AppointmentResource, ServiceLoopResource, UserSettingsResource, UserResource, CalendarResource, MaintenanceJobResource, HealthReportResource, WorkflowResource, JobTemplateResource, SettingsResource, PropertyMaintenanceItemResource, MaintenanceItemPackageResource, MaintenanceItemPrototypeResource,  MaintenanceItemTypeResource, MaintenanceItemTypeSchemaResource, ServiceLoopTemplateResource, MaintenanceLogResource, MaintenanceSystemsResource, MigrationStatusResource, ApiResource, EntityResource, AssociationResource, FormsMetadataResource, MediaResource, SystemMediaResource, ContactResource, ClientResource, PropertyResource, PropertyMaintenanceSystemsResource, client}